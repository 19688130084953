import React, { useState, useEffect } from "react";
import { FormattedMessage, injectIntl } from "react-intl";

import clsx from 'clsx';
import { useSubheader } from "../../_metronic/layout";

import { useFormik } from "formik";
import * as Yup from "yup";

import Autocomplete from '@material-ui/lab/Autocomplete';

import SaveIcon from '@material-ui/icons/Save';
import { makeStyles } from '@material-ui/core/styles';

import * as PatientService from '../services/PatientService';
import * as UtilService from '../services/UtilService';

import * as AlertService from '../AlertService';
import { useAlert } from 'react-alert';

import * as Constants from '../Constants';

import * as StringUtils from '../StringUtils';

import {
  TextField, MenuItem, FormControl, InputLabel, OutlinedInput, Select, Button,
  RadioGroup, FormControlLabel, Radio
} from "@material-ui/core";

import "./PatientNew.scss";

const useStyles = makeStyles(theme => ({
  button: {
    margin: theme.spacing(1),
  },
  leftIcon: {
    marginRight: theme.spacing(1),
  },
  rightIcon: {
    marginLeft: theme.spacing(1),
  },
  iconSmall: {
    fontSize: 20,
  },
}));


function PatientNew({ intl, ...props }) {


  const alert = useAlert();

  useEffect(() => {
    initialize();
  }, []);

  //START COMUNI PROVINCE
  const [provinceNascita, setProvinceNascita] = useState([]);
  const [comuniNascita, setComuniNascita] = useState([]);

  const [provinceAddress, setProvinceAddress] = useState([]);
  const [comuniAddress, setComuniAddress] = useState([]);

  const initialize = async () => {
    const rawProvince = await UtilService.getAllProvince();
    const province = await rawProvince.json();
    setProvinceNascita(province);
    setProvinceAddress(province);

  }

  const handleChangeProvNascita = (e, value) => {
    if (!StringUtils.isEmpty(value)) {
      formik.setFieldValue('birthPro', value.provinciasigla);
      getComuniBySiglaProvincia(value.provinciasigla, true);
    } else {
      formik.setFieldValue('birthPro', '');
      setComuniNascita([]);
    }
    formik.setFieldValue('birthTown', '');
  }

  const handleChangeComNascita = (e, value) => {
    if (!StringUtils.isEmpty(value)) {
      formik.setFieldValue('birthTown', value.comunedescrizione);
    } else {
      formik.setFieldValue('birthTown', '');
    }
  }

  const handleChangeProvAddress = (e, value) => {
    if (!StringUtils.isEmpty(value)) {
      formik.setFieldValue('addressPro', value.provinciasigla);
      getComuniBySiglaProvincia(value.provinciasigla, false);
    } else {
      formik.setFieldValue('addressPro', '');
      setComuniAddress([]);
    }
    formik.setFieldValue('addressTown', '');
  }

  const handleChangeComAddress = (e, value) => {
    if (!StringUtils.isEmpty(value)) {
      formik.setFieldValue('addressTown', value.comunedescrizione);
    } else {
      formik.setFieldValue('addressTown', '');
    }
  }

  const getComuniBySiglaProvincia = async (siglaProv, isNascita) => {
    const rawComuni = await UtilService.getComuniBySiglaProvincia(siglaProv);
    const comuni = await rawComuni.json();
    if (isNascita) {
      setComuniNascita(comuni);
    } else {
      setComuniAddress(comuni);
    }
  }
  //START COMUNI PROVINCE

  const suhbeader = useSubheader();
  suhbeader.setTitle(intl.formatMessage({ id: 'PAGES.NEW_PATIENT' }));
  const classes = useStyles();
  const inputLabel = React.useRef(null);
  const [labelWidth, setLabelWidth] = React.useState(0);
  React.useEffect(() => {
    setLabelWidth(inputLabel.current.offsetWidth);
  }, []);

  const newPatientSchema = Yup.object().shape({
    firstName: Yup.string()
      .required(intl.formatMessage({ id: 'ERRORS.REQUIRED_FIELD' })),
    lastName: Yup.string()
      .required(intl.formatMessage({ id: 'ERRORS.REQUIRED_FIELD' })),
    fiscalCode: Yup.string()
      .required(intl.formatMessage({ id: 'ERRORS.REQUIRED_FIELD' })),
    birthDate: Yup.date()
      .required(intl.formatMessage({ id: 'ERRORS.REQUIRED_FIELD' })),
    gender: Yup.string()
      .required(intl.formatMessage({ id: 'ERRORS.REQUIRED_FIELD' })),
    birthPro: Yup.string()
      .required(intl.formatMessage({ id: 'ERRORS.REQUIRED_FIELD' })),
    birthTown: Yup.string()
      .required(intl.formatMessage({ id: 'ERRORS.REQUIRED_FIELD' })),
    address: Yup.string()
      .required(intl.formatMessage({ id: 'ERRORS.REQUIRED_FIELD' })),
    addressPro: Yup.string()
      .required(intl.formatMessage({ id: 'ERRORS.REQUIRED_FIELD' })),
    addressTown: Yup.string()
      .required(intl.formatMessage({ id: 'ERRORS.REQUIRED_FIELD' })),
    phone: Yup.string()
      .required(intl.formatMessage({ id: 'ERRORS.REQUIRED_FIELD' })),
    email: Yup.string()
      .email(intl.formatMessage({ id: 'ERRORS.WRONG_EMAIL_FORMAT' }))
      .required(intl.formatMessage({ id: 'ERRORS.REQUIRED_FIELD' })),
    doctor: Yup.string()
      .required(intl.formatMessage({ id: 'ERRORS.REQUIRED_FIELD' })),
    typeMonitoring: Yup.string()
      .required(intl.formatMessage({ id: 'ERRORS.REQUIRED_FIELD' })),
    phoneDoctor: Yup.string(),
    caregiver: Yup.string(),
    caregiverPhone: Yup.string(),
    enabled: Yup.string(),
    voipUser: Yup.string()
      .required(intl.formatMessage({ id: 'ERRORS.REQUIRED_FIELD' })),
    // voipPassword: Yup.string()
    //   .required(intl.formatMessage({ id: 'ERRORS.REQUIRED_FIELD' })),
  });

  const formik = useFormik({
    initialValues: {
      firstName: '',
      lastName: '',
      fiscalCode: '',
      gender: 'Maschio',
      birthPro: '',
      birthTown: '',
      address: '',
      addressPro: '',
      addressTown: '',
      phone: '',
      email: '',
      doctor: '',
      typeMonitoring: '',
      phoneDoctor: '',
      caregiver: '',
      caregiverPhone: '',
      enabled: 'S',
      voipUser: '',
      // voipPassword: '',
    },
    validationSchema: newPatientSchema,
    onSubmit: values => {
      addPatient(values);
    },
  });

  const addPatient = async (newPatient) => {
    const rawData = await PatientService.addPatient(newPatient);
    if (rawData.status === Constants.STATUTS_201) {
      const data = await rawData.json();
      props.history.push(`/patient-edit/${data.id}`);
      AlertService.success(alert);
    } else {
      AlertService.error(alert);
    }
  }

  return (
    <div className="card card-custom gutter-b" style={{ background: "#fff", color: "#333" }}>


      <form onSubmit={formik.handleSubmit}>
        <div className="card-body">
          <div className="row">
            <div className="col-12 mt-4">
              <h6 className="text-primary" style={{ margin: "0" }}><FormattedMessage id="GENERAL.STRUCTUR" /></h6>
              <hr style={{ margin: "0" }} />
            </div>
            <div className="col-sm-6 col-md-4">
                <TextField
                  id="outlined-normal"
                  label={`${intl.formatMessage({ id: 'FIELD.LASTNAME' })} *`}
                  margin="normal"
                  variant="outlined"
                  size="small"
                  name="lastName"
                  onChange={formik.handleChange}
                  {...formik.getFieldProps("lastName")}
                />
                {formik.touched.lastName && formik.errors.lastName ?
                  <div className="fv-plugins-message-container">
                    <div className="fv-help-block">{formik.errors.lastName}</div>
                  </div>
                  : null}
            </div>
            <div className="col-sm-6 col-md-4">
              <TextField
                id="outlined-normal"
                label={`${intl.formatMessage({ id: 'FIELD.FIRSTNAME' })} *`}
                margin="normal"
                variant="outlined"
                size="small"
                name="firstName"
                onChange={formik.handleChange}
                {...formik.getFieldProps("firstName")}
              />
              {formik.touched.firstName && formik.errors.firstName ?
                <div className="fv-plugins-message-container">
                  <div className="fv-help-block">{formik.errors.firstName}</div>
                </div>
                : null}
            </div>
            <div className="col-sm-6 col-md-4">
              <TextField
                id="outlined-normal"
                label={`${intl.formatMessage({ id: 'FIELD.FISCAL_CODE' })} *`}
                margin="normal"
                variant="outlined"
                size="small"
                name="fiscalCode"
                onChange={formik.handleChange}
                {...formik.getFieldProps("fiscalCode")}
              />
              {formik.touched.fiscalCode && formik.errors.fiscalCode ?
                <div className="fv-plugins-message-container">
                  <div className="fv-help-block">{formik.errors.fiscalCode}</div>
                </div>
                : null}
            </div>

            <div className="col-sm-6 col-md-2">
              <FormControl margin="normal" size="small" variant="outlined" className={classes.formControl}>
                <InputLabel ref={inputLabel} htmlFor="outlined-gender-simple">
                  <FormattedMessage id="FIELD.GENDER" /> *
                </InputLabel>
                <Select
                  name="gender"
                  onChange={formik.handleChange}
                  {...formik.getFieldProps("gender")}
                  input={<OutlinedInput labelWidth={labelWidth} name="gender" id="outlined-gender-simple" />}
                >
                  <MenuItem value={'Maschio'}><FormattedMessage id="FIELD.TYPE_GENDER_MALE" /></MenuItem>
                  <MenuItem value={'Femmina'}><FormattedMessage id="FIELD.TYPE_GENDER_FEMALE" /></MenuItem>
                </Select>
              </FormControl>
              {formik.touched.gender && formik.errors.gender ?
                <div className="fv-plugins-message-container">
                  <div className="fv-help-block">{formik.errors.gender}</div>
                </div>
                : null}
            </div>

            <div className="col-sm-6 col-md-2">
              <TextField
                id="date"
                label={`${intl.formatMessage({ id: 'FIELD.BIRTH_DATE' })} *`}
                type="date"
                variant="outlined"
                size="small"
                margin="normal"
                className={classes.textField}
                InputLabelProps={{
                  shrink: true,
                }}
                name="birthDate"
                onChange={formik.handleChange}
                {...formik.getFieldProps("birthDate")}
              />
              {formik.touched.birthDate && formik.errors.birthDate ?
                <div className="fv-plugins-message-container">
                  <div className="fv-help-block">{formik.errors.birthDate}</div>
                </div>
                : null}
            </div>

            <div className="col-sm-6 col-md-1">
              <Autocomplete
                id="combo-box-demo"
                onChange={handleChangeProvNascita}
                options={provinceNascita}
                getOptionLabel={(option) => option.provinciasigla}
                renderInput={(params) => <TextField
                  {...params}
                  id="outlined-normal"
                  label={`${intl.formatMessage({ id: 'FIELD.PROV' })} *`}
                  margin="normal"
                  variant="outlined"
                  size="small"
                  name="birthPro"
                  {...formik.getFieldProps("birthPro")}
                />}
              />
              {formik.touched.birthPro && formik.errors.birthPro ?
                <div className="fv-plugins-message-container">
                  <div className="fv-help-block">{formik.errors.birthPro}</div>
                </div>
                : null}
            </div>
            <div className="col-sm-6 col-md-3">
              <Autocomplete
                id="combo-box-demo"
                disabled={StringUtils.isEmpty(formik.values.birthPro)}
                onChange={handleChangeComNascita}
                options={comuniNascita}
                getOptionLabel={(option) => option.comunedescrizione}
                renderInput={(params) => <TextField
                  {...params}
                  id="outlined-normal"
                  label={`${intl.formatMessage({ id: 'FIELD.BIRTH_COMMON' })} *`}
                  margin="normal"
                  variant="outlined"
                  size="small"
                  name="birthTown"
                  {...formik.getFieldProps("birthTown")}
                />}
              />
              {formik.touched.birthTown && formik.errors.birthTown ?
                <div className="fv-plugins-message-container">
                  <div className="fv-help-block">{formik.errors.birthTown}</div>
                </div>
                : null}
            </div>



          </div>

          <div className="row">
            <div className="col-12 mt-4">
              <h6 className="text-primary" style={{ margin: "0" }}><FormattedMessage id="GENERAL.CONTACTS" /></h6>
              <hr style={{ margin: "0" }} />
            </div>

            <div className="col-sm-6 col-md-4">
              <TextField
                id="outlined-normal"
                label={`${intl.formatMessage({ id: 'FIELD.HOME_ADDRESS' })} *`}
                margin="normal"
                variant="outlined"
                size="small"
                name="address"
                onChange={formik.handleChange}
                {...formik.getFieldProps("address")}
              />
              {formik.touched.address && formik.errors.address ?
                <div className="fv-plugins-message-container">
                  <div className="fv-help-block">{formik.errors.address}</div>
                </div>
                : null}
            </div>
            <div className="col-sm-6 col-md-1">
              <Autocomplete
                id="combo-box-demo"
                onChange={handleChangeProvAddress}
                options={provinceAddress}
                getOptionLabel={(option) => option.provinciasigla}
                renderInput={(params) => <TextField
                  {...params}
                  id="outlined-normal"
                  label={`${intl.formatMessage({ id: 'FIELD.PROV' })} *`}
                  margin="normal"
                  variant="outlined"
                  size="small"
                  name="addressPro"
                  {...formik.getFieldProps("addressPro")}
                />}
              />
              {formik.touched.addressPro && formik.errors.addressPro ?
                <div className="fv-plugins-message-container">
                  <div className="fv-help-block">{formik.errors.addressPro}</div>
                </div>
                : null}
            </div>
            <div className="col-sm-6 col-md-3">
              <Autocomplete
                id="combo-box-demo"
                disabled={StringUtils.isEmpty(formik.values.addressPro)}
                onChange={handleChangeComAddress}
                options={comuniAddress}
                getOptionLabel={(option) => option.comunedescrizione}
                renderInput={(params) => <TextField
                  {...params}
                  id="outlined-normal"
                  label={`${intl.formatMessage({ id: 'FIELD.HOME_COMMON' })} *`}
                  margin="normal"
                  variant="outlined"
                  size="small"
                  name="addressTown"
                  {...formik.getFieldProps("addressTown")}
                />}
              />
              {formik.touched.addressTown && formik.errors.addressTown ?
                <div className="fv-plugins-message-container">
                  <div className="fv-help-block">{formik.errors.addressTown}</div>
                </div>
                : null}
            </div>
            <div className="col-sm-6 col-md-4">
              <TextField
                id="outlined-normal"
                label={`${intl.formatMessage({ id: 'USER.EMAIL' })} *`}
                margin="normal"
                variant="outlined"
                size="small"
                name="email"
                onChange={formik.handleChange}
                {...formik.getFieldProps("email")}
              />
              {formik.touched.email && formik.errors.email ?
                <div className="fv-plugins-message-container">
                  <div className="fv-help-block">{formik.errors.email}</div>
                </div>
                : null}
            </div>



            <div className="col-sm-6 col-md-4">
              <TextField
                id="outlined-normal"
                label={`${intl.formatMessage({ id: 'FIELD.PHONE' })} *`}
                margin="normal"
                variant="outlined"
                size="small"
                name="phone"
                onChange={formik.handleChange}
                {...formik.getFieldProps("phone")}
              />
              {formik.touched.phone && formik.errors.phone ?
                <div className="fv-plugins-message-container">
                  <div className="fv-help-block">{formik.errors.phone}</div>
                </div>
                : null}
            </div>

          </div>
          <div className="row">
            <div className="col-12 mt-4">
              <h6 className="text-primary" style={{ margin: "0" }}><FormattedMessage id="GENERAL.TYPE_MONITORING" /> *</h6>
              <hr style={{ margin: "0" }} />
            </div>
            <div className="col">
              <FormControl component="fieldset" className={classes.formControl}>
                <RadioGroup
                  name="informazioni"
                  row
                  className={classes.group}
                  onChange={formik.handleChange}
                  {...formik.getFieldProps("typeMonitoring")}
                >
                  <FormControlLabel value="C19" control={<Radio />} label={intl.formatMessage({ id: 'FIELD.TYPE_MONITORING_C19' })} />
                  <FormControlLabel value="IRES" control={<Radio />} label={intl.formatMessage({ id: 'FIELD.TYPE_MONITORING_IRES' })} />
                  <FormControlLabel value="ICAR" control={<Radio />} label={intl.formatMessage({ id: 'FIELD.TYPE_MONITORING_ICAR' })} />
                </RadioGroup>
              </FormControl>
            </div>
          </div>
          <div className="row">
            <div className="col-12 my-4">
              <h6 className="text-primary" style={{ margin: "0" }}><FormattedMessage id="GENERAL.CAREGIVER" /></h6>
              <hr style={{ margin: "0" }} />
            </div>

            <div className="col-sm-6 col-md-3">
              <TextField
                id="outlined-normal"
                label={`${intl.formatMessage({ id: 'FIELD.CAREGIVER' })}`}
                margin="normal"
                variant="outlined"
                size="small"
                name="caregiver"
                onChange={formik.handleChange}
                {...formik.getFieldProps("caregiver")}
              />
            </div>
            <div className="col-sm-6 col-md-3">
              <TextField
                id="outlined-normal"
                label={`${intl.formatMessage({ id: 'FIELD.CAREGIVER_CONTACT' })}`}
                margin="normal"
                variant="outlined"
                size="small"
                name="caregiverPhone"
                onChange={formik.handleChange}
                {...formik.getFieldProps("caregiverPhone")}
              />
            </div>
            <div className="col-sm-6 col-md-3">
              <TextField
                id="outlined-normal"
                label={`${intl.formatMessage({ id: 'FIELD.OWN_DOCTOR' })} *`}
                margin="normal"
                variant="outlined"
                size="small"
                name="doctor"
                onChange={formik.handleChange}
                {...formik.getFieldProps("doctor")}
              />
              {formik.touched.doctor && formik.errors.doctor ?
                <div className="fv-plugins-message-container">
                  <div className="fv-help-block">{formik.errors.doctor}</div>
                </div>
                : null}
            </div>
            <div className="col-sm-6 col-md-3">
              <TextField
                id="outlined-normal"
                label={`${intl.formatMessage({ id: 'FIELD.OWN_DOCTOR_CONTACT' })}`}
                margin="normal"
                variant="outlined"
                size="small"
                name="phoneDoctor"
                onChange={formik.handleChange}
                {...formik.getFieldProps("phoneDoctor")}
              />
            </div>
          </div>
          <div className="row">
            <div className="col-12 mt-4">
              <h6 className="text-primary" style={{ margin: "0" }}><FormattedMessage id="GENERAL.VOIP_DATAS" /></h6>
              <hr style={{ margin: "0" }} />
            </div>
            <div className="col-sm-6 col-md-4">
              <TextField
                id="outlined-normal"
                label={`${intl.formatMessage({ id: 'FIELD.VOIP_INTERNAL' })} *`}
                margin="normal"
                variant="outlined"
                size="small"
                name="voipUser"
                onChange={formik.handleChange}
                {...formik.getFieldProps("voipUser")}
              />
              {formik.touched.voipUser && formik.errors.voipUser ?
                <div className="fv-plugins-message-container">
                  <div className="fv-help-block">{formik.errors.voipUser}</div>
                </div>
                : null}
            </div>
            {/* <div className="col-sm-6 col-md-4">
              <TextField
                id="outlined-normal"
                label={`${intl.formatMessage({ id: 'FIELD.VOIP_PASSWORD' })} *`}
                margin="normal"
                variant="outlined"
                size="small"
                name="voipPassword"
                onChange={formik.handleChange}
                {...formik.getFieldProps("voipPassword")}
              />
              {formik.touched.voipPassword && formik.errors.voipPassword ?
                <div className="fv-plugins-message-container">
                  <div className="fv-help-block">{formik.errors.voipPassword}</div>
                </div>
                : null}
            </div> */}
          </div>
        </div>
        <div className="card-footer py-4">

          <Button type="submit" variant="contained" color="primary" size="small" className={classes.button}
            disabled={!formik.isValid || !formik.dirty}
          >
            <FormattedMessage id="ACTIONS.SAVE" />
            <SaveIcon className={clsx(classes.rightIcon, classes.iconSmall)} />
          </Button>

          <span className="text-muted float-right mt-4">* <FormattedMessage id="GENERAL.REQUIRED_FIELDS" /></span>
        </div>
      </form>
    </div>
  );
}

export default injectIntl(PatientNew);
