import React, { useEffect } from "react";
import { useSubheader } from "../../_metronic/layout";
import { injectIntl } from "react-intl";

import * as JsSIP from 'jssip'




function Voiptre() {
    const subHeader = useSubheader();
    subHeader.setTitle("Voip Tre");


    useEffect(() => {
        // doctorPhone.start();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    var socket = new JsSIP.WebSocketInterface('wss://voip.patientmonitoring.eositaly.it:7443');
    var configuration = {
        sockets: [socket],
        uri: 'sip:1011@voip.patientmonitoring.eositaly.it',
        password: 'UAiWFkJsmf',
        realm: 'voip.patientmonitoring.eositaly.it'
        // session_timers: false
    };

    var doctorPhone = new JsSIP.UA(configuration);

    var callOptions = {
        mediaConstraints: {
            audio: true, // only audio calls
            video: false
        }
    };
    doctorPhone.start();

    // var remoteAudio = document.getElementById('remoteAudio');

    const audio = new window.Audio();

    doctorPhone.on('connected', function (e) {
        console.log("Dottore Connesso");
        console.log(doctorPhone.isRegistered());
    });

    doctorPhone.on('registrationFailed', function (e) {
        console.log(e);
    });

    doctorPhone.on('newRTCSession', function (evt) {
        var session = evt.session;

        //Verifico se la chiamata è in uscita o in entrata
        if (session.direction === "incoming") {
            // // incoming call here
            // session.on("accepted", function (e) {
            //     console.log("accepted " + e);
            //     // the call has answered
            // });
            // session.on("confirmed", function (e) {
            //     console.log("confirmed " + e);
            //     // this handler will be called for incoming calls too
            // });
            // session.on("ended", function (e) {
            //     console.log("ended " + e);
            //     // the call has ended
            // });
            // session.on("failed", function (e) {
            //     console.log("failed ", e);
            //     // unable to establish the call
            // });
            session.on('addstream', function (e) {
                // set remote audio stream (to listen to remote audio)
                // remoteAudio is <audio> element on page
                const remoteAudio = audio;
                remoteAudio.src = window.URL.createObjectURL(e.stream);
                remoteAudio.play();
            });

            //Answer call
            session.answer(callOptions);

            // // Reject call (or hang up it)
            // session.terminate();
        } else {
            console.log("Stai chiamando");
            session.on('addstream', function(e){
                // set remote audio stream (to listen to remote audio)
                // remoteAudio is <audio> element on page
                const remoteAudio = audio;
                remoteAudio.src = window.URL.createObjectURL(e.stream);
                remoteAudio.play();
            });
        }

        // session.on('peerconnection', function (data) {
        //     console.log('peerconnection');
        //     data.peerconnection.addEventListener('addstream', function (e) {
        //         console.log('addstream');
        //         // set remote audio stream
        //         const remoteAudio = document.createElement('audio');
        //         remoteAudio.src = window.URL.createObjectURL(e.stream);
        //         remoteAudio.play();
        //     });
        // });
    });

    const call = () => {
        const session = doctorPhone.call('sip:1003@voip.patientmonitoring.eositaly.it', {
            'eventHandlers': {
                'progress': function (e) {
                    console.log('call is in progress');
                },
                'failed': function (e) {
                    console.log('call failed with cause: ' + JSON.stringify(e));
                },
                'ended': function (e) {
                    console.log('call ended with cause: ' + JSON.stringify(e));
                },
                'confirmed': function (e) {
                    // const remoteAudio = document.createElement('audio');
                    // remoteAudio.src = window.URL.createObjectURL(e.stream);
                    // remoteAudio.play();
                    console.log("Call confirmed");
                },
                'addstream': function (e) {
                    console.log('Add stream (event handlers)')
                    audio.srcObject = e.stream
                    audio.play()
                }
            },
            'mediaConstraints': { 'audio': true, 'video': false },
            'sessionTimersExpires': 120,
            'extraHeaders': ['X-Foo: foo', 'X-Bar: bar'],
            'pcConfig': {
                'iceServers': [
                    {
                        'urls': ['stun:stun.l.google.com:19302', 'stun:stun1.l.google.com:19302']
                    }
                ]
            }
        });

        if (session.connection) {
            console.log('Connection is valid')

            session.connection.addEventListener('addstream', e => {
                console.log('Add stream')
                audio.srcObject = e.stream
                audio.play()
            })

            session.on('addstream', function (e) {
                // set remote audio stream (to listen to remote audio)
                // remoteAudio is <audio> element on page
                const remoteAudio = audio
                remoteAudio.src = window.URL.createObjectURL(e.stream);
                remoteAudio.play();
            });
            session.connection.addEventListener('peerconnection', e => {
                console.log('Peer connection')
                audio.srcObject = e.stream
                audio.play()
            })
        } else {
            console.log('Connection is null')
        }
    }

    return (
        <>
            {/* <video id="remoteVideo"></video>
            <video id="localVideo" muted="muted"></video> */}
            <audio id="remoteAudio"></audio>
            <button className="btn-action btn-success" onClick={call}>Call</button>
        </>
    );
}


export default injectIntl(Voiptre);