import * as Constants from '../Constants';
import * as Utils from '../Utils';


export async function getIdsOnAir() {
    const authToken = sessionStorage.getItem('loggedUserToken');
    const api = Constants.BACKEND_URL + Constants.API_PATIENT+Constants.API_IDS_ON_AIR;
    return await fetch (api, {
        method: Constants.METHOD_GET,
        headers: {
            'Authorization': `authToken ${authToken}`
        }
    });
}

export async function findPatientsByFilter(filter) {
    const authToken = sessionStorage.getItem('loggedUserToken');
    var api = Constants.BACKEND_URL + Constants.API_PATIENT+`?&pagination_enabled=true&pageNumber=${filter.page}&pageSize=${filter.limit}`;
    
    api = !Utils.isBlank(filter.findAnywhere) ? `${api}&findAnywhere=${filter.findAnywhere}` : api; //findAnywhere
    api = !Utils.isBlank(filter.typeMonitoring) ? `${api}&typeMonitoring=${filter.typeMonitoring}` : api; //typeMonitoring
    api = !Utils.isBlank(filter.enabled) ? `${api}&enabled=${filter.enabled}` : api; //enabled
    api = !Utils.isBlank(filter.online) ? `${api}&online=${filter.online}` : api; //online
    api = !Utils.isBlank(filter.lastMeasureAlert) ? `${api}&lastMeasureAlert=${filter.lastMeasureAlert}` : api; //lastMeasureAlert
    api = !Utils.isBlank(filter.lastTemperatureAlert) ? `${api}&lastTemperatureAlert=${filter.lastTemperatureAlert}` : api; //lastTemperatureAlert
    api = !Utils.isBlank(filter.lastSaturationAlert) ? `${api}&lastSaturationAlert=${filter.lastSaturationAlert}` : api; //lastSaturationAlert

    return await fetch (api, {
        method: Constants.METHOD_GET,
        headers: {
            'Authorization': `authToken ${authToken}`
        }
    });
}

export async function findPatientById(id) {
    const authToken = sessionStorage.getItem('loggedUserToken');
    const api = Constants.BACKEND_URL + Constants.API_PATIENT+`/${id}`;
    return await fetch (api, {
        method: Constants.METHOD_GET,
        headers: {
            'Authorization': `authToken ${authToken}`
        }
    });
}

export async function addPatient(patient) {
    const authToken = sessionStorage.getItem('loggedUserToken');
    const api = Constants.BACKEND_URL + Constants.API_PATIENT;
    return await fetch (api, {
        method: Constants.METHOD_POST,
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': `authToken ${authToken}`
          },
        body: JSON.stringify(patient)
    });
}

export async function updatePatient(id, patient) {
    const authToken = sessionStorage.getItem('loggedUserToken');
    const api = Constants.BACKEND_URL + Constants.API_PATIENT+`/${id}`;
    return await fetch (api, {
        method: Constants.METHOD_PUT,
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': `authToken ${authToken}`
          },
        body: JSON.stringify(patient)
    });
}

export async function deletePatient(id) {
    const authToken = sessionStorage.getItem('loggedUserToken');
    const api = Constants.BACKEND_URL + Constants.API_PATIENT+`/${id}`;
    return await fetch (api, {
        method: Constants.METHOD_DELETE,
        headers: {
            'Authorization': `authToken ${authToken}`
        }
    });
}


export async function enablePatient(id, enabled) {
    const authToken = sessionStorage.getItem('loggedUserToken');
    const api = Constants.BACKEND_URL + Constants.API_PATIENT+`/${id}`+Constants.API_ENABLING_CHANGE;
    return await fetch (api, {
        method: Constants.METHOD_PUT,
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': `authToken ${authToken}`
          },
        body: JSON.stringify(enabled)
    });
}

export async function sendNotification(id, message) {
    const authToken = sessionStorage.getItem('loggedUserToken');
    const api = Constants.BACKEND_URL + Constants.API_PATIENT+`/${id}`+Constants.API_NOTIFICATION;
    return await fetch (api, {
        method: Constants.METHOD_POST,
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': `authToken ${authToken}`
        },
        body: JSON.stringify(message)
    });
}

export async function getSummaryMeasureByPatient(idPatient, hours, timestamp) {
    const authToken = sessionStorage.getItem('loggedUserToken');
    let api = Constants.BACKEND_URL + Constants.API_PATIENT+`/${idPatient}`+Constants.API_MEASUREMENT+`?`;
    api = hours === null ? api : api+`hours=${hours}&`;
    api = timestamp === null ? api : api+`timestamp=${timestamp}`;
    return await fetch (api, {
        method: Constants.METHOD_GET,
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': `authToken ${authToken}`
        },
    });
}

export async function getDetailMeasureByPatient(idPatient, timestamp, seconds) {
    const authToken = sessionStorage.getItem('loggedUserToken');
    let api = Constants.BACKEND_URL + Constants.API_PATIENT+`/${idPatient}`+Constants.API_MEASUREMENT+`/${timestamp}`
    api = seconds === null ? api : api+`?seconds=${seconds}`
    return await fetch (api, {
        method: Constants.METHOD_GET,
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': `authToken ${authToken}`
        },
    });
}

export async function printCsv(idPatient, timestamp, type) {
    const authToken = sessionStorage.getItem('loggedUserToken');
    let api = Constants.BACKEND_URL + Constants.API_PATIENT+`/${idPatient}`+Constants.API_MEASUREMENT+`/${timestamp}/${type}/csv`
    return await fetch (api, {
        method: Constants.METHOD_GET,
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': `authToken ${authToken}`
        },
    });
}

export async function getMeasurePaginationByMeasureType(idPatient, measureType, page, limit) {
    const authToken = sessionStorage.getItem('loggedUserToken');
    const api = Constants.BACKEND_URL + Constants.API_PATIENT+`/${idPatient}`+Constants.API_MEASURE+`/paged/${measureType}?pagination_enabled=true&pageNumber=${page}&pageSize=${limit}`;
    return await fetch (api, {
        method: Constants.METHOD_GET,
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': `authToken ${authToken}`
        },
    });
}

// export async function getLastPatientSaturation(idPatient) {
//     const authToken = sessionStorage.getItem('loggedUserToken');
//     const api = Constants.BACKEND_URL + Constants.API_PATIENT+`/${idPatient}`+Constants.LAST_SATURATION;
//     return await fetch (api, {
//         method: Constants.METHOD_GET,
//         headers: {
//             'Accept': 'application/json',
//             'Content-Type': 'application/json',
//             'Authorization': `authToken ${authToken}`
//         },
//     });
// }
