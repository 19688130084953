import React, { useEffect, useState, Suspense } from "react";
import { Redirect, Switch, Route } from "react-router-dom";
import { LayoutSplashScreen, ContentRoute } from "../_metronic/layout";
import { DashboardPage } from "./pages/DashboardPage";
import DoctorDashboard from "./pages/DoctorDashboard";
import PatientNew from "./pages/PatientNew";
import PatientEdit from "./pages/PatientEdit";
import PatientMonitoredList from "./pages/PatientMonitoredList";
import PatientMedicalHistoryCard from "./pages/PatientMedicalHistoryCard";
import PatientMedicalHistoryCardDetail from "./pages/PatientMedicalHistoryCardDetail";
import PatientCovidHistoryCard from "./pages/PatientCovidHistoryCard";
import PatientCovidHistoryCardDetail from "./pages/PatientCovidHistoryCardDetail";
import PatientLiveMonitoring from "./pages/PatientLiveMonitoring";
import PatientLiveMonitoringDetail from "./pages/PatientLiveMonitoringDetail";
// import PatientLiveMonitoringDetailRT from "./pages/PatientLiveMonitoringDetailRT";
// import PatientLiveMonitoringDetailOld from "./pages/PatientLiveMonitoringDetailOld";
import PatientHistoricMonitoring from "./pages/PatientHistoricMonitoring";
import PatientHistoricMeasureDetail from "./pages/PatientHistoricMeasureDetail";
import PatientHistoricMonitoringChart from "./pages/PatientHistoricMonitoringChart";
import DoctorNew from "./pages/DoctorNew";
import DoctorEdit from "./pages/DoctorEdit";
import DoctorList from "./pages/DoctorList";
import AlertCustomization from './pages/AlertCustomization';
import DownloadApps from './pages/DownloadApps';

import Voip from "./pages/Voip";
import VoipDue from "./pages/VoipDue";
import VoipTre from "./pages/VoipTre";
import VoipQuattro from "./pages/VoipQuattro";


import * as C from './Constants'
import * as JsSIP from 'jssip'
import CallingModal from './components/voip/CallingModal';
// import AnswerModal from './components/voip/AnswerModal';
import { StickyToolbarVoip } from "./components/voip/StickyToolbarVoip";

// window.onbeforeunload = (e) => {
//     alert("STAI REFRESHANDO");
// };

const loggedUserVoipUsername = sessionStorage.getItem('loggedUserVoipUsername');
const loggedUserVoipPassword = sessionStorage.getItem('loggedUserVoipPassword');

const voipUri = `${C.VOIP_URI_PREFIX}${loggedUserVoipUsername}${C.VOIP_URI_SUFFIX}`

var socket = new JsSIP.WebSocketInterface(C.VOIP_WS_URL);
var configuration = {
    sockets: [socket],
    uri: voipUri,
    password: loggedUserVoipPassword,
    realm: C.VOIP_REALM,
    register_expires: 300
};

var doctorPhone = new JsSIP.UA(configuration);

export default function BasePage() {
    useEffect(() => {
        doctorPhone.start();
        doctorPhone.on('connected', function (e) {
            console.log(`${sessionStorage.getItem('loggedUserName')} CONNESSO`);
        });

        doctorPhone.on('registered', function (e) {
            console.log(`${sessionStorage.getItem('loggedUserName')} REGISTRATO`);
        });

        doctorPhone.on('unregistered', function (e) {
            console.log(`TUTTI I BINDING DI ${sessionStorage.getItem('loggedUserName')} CANCELLATI`);
        });


        doctorPhone.on('newRTCSession', function (evt) {
            var session = evt.session;

            //Verifico se la chiamata è in uscita o in entrata
            if (session.direction === "incoming") {
                // incoming call here
                // session.on("accepted", function (e) {
                //     console.log("accepted " + e);
                //     // the call has answered
                // });
                // session.on("confirmed", function (e) {
                //     console.log("confirmed " + e);
                //     // this handler will be called for incoming calls too
                // });
                // session.on("ended", function (e) {
                //     console.log("ended " + e);
                //     // the call has ended
                // });
                // session.on("failed", function (e) {
                //     console.log("failed ", e);
                //     // unable to establish the call
                // });

                // setIsOpenAnswerModal(true);
                console.log("Ti stanno chiamando");

                session.on('confirmed', function (e) {
                    console.log("chiamata confermata");
                });

                session.on('addstream', function (e) {
                    const remoteAudio = audio;
                    remoteAudio.src = window.URL.createObjectURL(e.stream);
                    remoteAudio.play();
                });

                session.answer({
                    mediaConstraints: {
                        'audio': true,
                        'video': false
                    },
                    pcConfig: {
                        'iceServers': [
                            {
                                'urls': ['stun:stun.l.google.com:19302', 'stun:stun1.l.google.com:19302']
                            }
                        ],
                        'iceTransportPolicy': 'all',
                        'rtcpMuxPolicy': 'negotiate'
                    }
                });

                setCallingSession(session);
            } else {
                setIsOpenCallingModal(true);
                console.log("Stai chiamando");
                session.on('addstream', function (e) {
                    const remoteAudio = audio;
                    remoteAudio.src = window.URL.createObjectURL(e.stream);
                    remoteAudio.play();
                });
            }
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    //START VOIP
    const [isOpenCallingModal, setIsOpenCallingModal] = useState(false);
    const [isVisibleStickiToolbarVoip, setIsVisibleStickiToolbarVoip] = useState(false);
    // const [isOpenAnswerModal, setIsOpenAnswerModal] = useState(false);
    const [isCallMuted, setIsCallMuted] = useState(false);


    const audio = new window.Audio();
    // const anotherAudio = new window.Audio();
    // const [audioFunzionante, setAudioFunzionante] = useState();
    const [patientDetails, setPatientDetails] = useState({
        firstName: '',
        lastName: '',
        typeMonitoring: '',
        email: '',
        gender: '',
        birthDate: ''
    });
    const [callingSession, setCallingSession] = useState({});

    const call = (patient) => {
        if (!doctorPhone.isRegistered()) {
            doctorPhone.start();
        }
        setIsCallMuted(false);
        const session = doctorPhone.call(`${C.VOIP_URI_PREFIX}${patient.voipUser}${C.VOIP_URI_SUFFIX}`, {
            'eventHandlers': {
                'progress': function (e) {
                    console.log('call is in progress');
                },
                'failed': function (e) {
                    setIsCallMuted(false);
                    setIsOpenCallingModal(false);
                    setIsVisibleStickiToolbarVoip(false);
                    setCallingSession({});
                    console.log('Chiamata Fallita');
                },
                'ended': function (e) {
                    setIsCallMuted(false);
                    setIsOpenCallingModal(false);
                    setIsVisibleStickiToolbarVoip(false);
                    setCallingSession({});
                    console.log('Chiamata Terminata');
                },
                'confirmed': function (e) {
                    // const remoteAudio = document.createElement('audio');
                    // remoteAudio.src = window.URL.createObjectURL(e.stream);
                    // remoteAudio.play();
                    console.log("Call confirmed");
                },
                'addstream': function (e) {
                    console.log('Add stream (event handlers)')
                    audio.srcObject = e.stream
                    audio.play()
                }
            },
            'mediaConstraints': { 'audio': true, 'video': false },
            'sessionTimersExpires': 120,
            'extraHeaders': ['X-Foo: foo', 'X-Bar: bar'],
            'pcConfig': {
                'iceServers': [
                    {
                        'urls': ['stun:stun.l.google.com:19302', 'stun:stun1.l.google.com:19302']
                    }
                ]
            }
        });

        if (session.connection) {
            console.log('Connection is valid')

            session.connection.addEventListener('addstream', e => {
                console.log('Add stream')
                audio.srcObject = e.stream
                audio.play()
            })

            session.on('addstream', function (e) {
                console.log('Add stream 2')
                const mediaStream = new MediaStream(e.stream);
                const remoteAudio = document.getElementById('remoteAudio');
                remoteAudio.srcObject = mediaStream;
                remoteAudio.play();
            });
            session.connection.addEventListener('peerconnection', e => {
                console.log('Peer connection')
                audio.srcObject = e.stream
                audio.play()
            })
        } else {
            console.log('Connection is null')
        }

        setPatientDetails({
            firstName: patient.firstName,
            lastName: patient.lastName,
            typeMonitoring: patient.typeMonitoring,
            email: patient.email,
            gender: patient.gender,
            birthDate: patient.birthDate
        });
        setCallingSession(session);
    }

    // const answerCall = () => {
    //     const callOptions = {
    //         mediaConstraints: {
    //             'audio': true,
    //             'video': false
    //         },
    //         pcConfig: {
    //             'iceServers': [
    //                 {
    //                     'urls': ['stun:stun.l.google.com:19302', 'stun:stun1.l.google.com:19302']
    //                 }
    //             ],
    //             'iceTransportPolicy': 'all',
    //             'rtcpMuxPolicy': 'negotiate'
    //         }
    //     }

    //     callingSession.answer(callOptions);

    //     console.log('Connection is valid')

    //     callingSession.on('confirmed', e => {
    //         console.log("call confirmed");
    //     })

    //     callingSession.connection.addEventListener('addstream', e => {
    //         // console.log('Add stream')
    //         // audio.srcObject = e.stream
    //         // audio.play()

    //         const mediaStream = new MediaStream(e.stream);
    //         const remoteAudio = document.getElementById('remoteAudio');
    //         remoteAudio.srcObject = mediaStream;
    //         remoteAudio.play();
    //     })

    //     callingSession.on('addstream', function (e) {
    //         const mediaStream = new MediaStream(e.stream);
    //         const remoteAudio = document.getElementById('remoteAudio');
    //         remoteAudio.srcObject = mediaStream;
    //         remoteAudio.play();
    //     });
    //     callingSession.connection.addEventListener('peerconnection', e => {
    //         console.log('Peer connection')
    //         audio.srcObject = e.stream
    //         audio.play()
    //     })
    // }

    const maximize = () => {
        setIsOpenCallingModal(true);
        setIsVisibleStickiToolbarVoip(false);
    }

    const minimize = () => {
        setIsOpenCallingModal(false);
        setIsVisibleStickiToolbarVoip(true);
    }

    const endCall = () => {
        callingSession.terminate();
    }

    const mute = () => {
        callingSession.mute();
        setIsCallMuted(true);
    }

    const unmute = () => {
        callingSession.unmute();
        setIsCallMuted(false);
    }

    //END VOIP

    return (
        <>
            <audio id="remoteAudio"></audio>
            <StickyToolbarVoip
                key={`stickyToolbarVoip-${isVisibleStickiToolbarVoip}`}
                isVisibleStickiToolbarVoip={isVisibleStickiToolbarVoip}
                patientDetails={patientDetails}
                isCallMuted={isCallMuted}
                mute={mute}
                unmute={unmute}
                maximize={maximize}
                endCall={endCall} />
            <CallingModal
                key={`callingModal-${isOpenCallingModal}`}
                isModalOpen={isOpenCallingModal}
                patientDetails={patientDetails}
                isCallMuted={isCallMuted}
                mute={mute}
                unmute={unmute}
                minimize={minimize}
                endCall={endCall} />
            {/* <AnswerModal key={`answerModal-${isOpenAnswerModal}`} isModalOpen={isOpenAnswerModal} answerCall={answerCall} /> */}
            <Suspense fallback={<LayoutSplashScreen />}>
                <Switch>
                    {
                        /* Redirect from root URL to /dashboard. */
                        <Redirect exact from="/" to="/dashboard" />
                    }
                    <ContentRoute path="/voip" component={Voip} />
                    <ContentRoute path="/voip-due" component={VoipDue} />
                    <ContentRoute path="/voip-tre" component={VoipTre} />
                    <ContentRoute path="/voip-quattro" component={VoipQuattro} />
                    <ContentRoute path="/old-dashboard" component={DashboardPage} />
                    <ContentRoute path="/dashboard" component={DoctorDashboard} />
                    <ContentRoute path="/patient-new" component={PatientNew} />
                    <ContentRoute path="/patient-edit/:id" component={PatientEdit} />
                    <Route path="/patient-monitored-list" render={(props) => <PatientMonitoredList voipCall={call} {...props} />} />
                    <ContentRoute path="/patient-covid-history-card" component={PatientCovidHistoryCard} />
                    <ContentRoute path="/patient-covid-history-card-detail/:id" component={PatientCovidHistoryCardDetail} />
                    <ContentRoute path="/patient-medical-history-card" component={PatientMedicalHistoryCard} />
                    <ContentRoute path="/patient-medical-history-card-detail/:id" component={PatientMedicalHistoryCardDetail} />
                    <ContentRoute path="/patient-live-monitoring" component={PatientLiveMonitoring} />
                    <Route path="/patient-live-monitoring-detail/:id" render={(props) => <PatientLiveMonitoringDetail voipCall={call} {...props} />} />
                    {/* <Route path="/patient-live-monitoring-detail-rt/:id" render={(props) => <PatientLiveMonitoringDetailRT voipCall={call} {...props} />} />
                    <Route path="/patient-live-monitoring-detail-old/:id" render={(props) => <PatientLiveMonitoringDetailOld voipCall={call} {...props} />} /> */}
                    <ContentRoute path="/patient-historic-monitoring" component={PatientHistoricMonitoring} />
                    <Route path="/patient-historic-measure-detail/:id" render={(props) => <PatientHistoricMeasureDetail voipCall={call} {...props} />} />
                    <ContentRoute path="/patient-historic-monitoring-chart/:id" component={PatientHistoricMonitoringChart} />
                    <ContentRoute path="/doctor-new" component={DoctorNew} />
                    <ContentRoute path="/doctor-edit/:id" component={DoctorEdit} />
                    <ContentRoute path="/doctor-list" component={DoctorList} />
                    <ContentRoute path="/notifications" component={AlertCustomization} />
                    <ContentRoute path="/download-apps" component={DownloadApps} />
                    <Redirect to="/dashboard" />
                </Switch>
            </Suspense>
        </>
    );
}
