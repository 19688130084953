import React, { useState, useEffect, useRef, useLayoutEffect } from "react";
import { NavLink } from "react-router-dom";
import { FormattedMessage, injectIntl } from "react-intl";
import { BrowserView, MobileView } from "react-device-detect";
import { useSubheader } from "../../_metronic/layout";
import { format as dateFormat } from 'date-fns';
import * as C from "../Constants";
import * as Utils from "../Utils";
import * as PatientService from '../services/PatientService';
import * as LiveService from '../services/LiveService';
import SVG from "react-inlinesvg";
import { toAbsoluteUrl } from "../../_metronic/_helpers";

//Dialog

import { VictoryContainer, VictoryLine } from 'victory';

import NotificationModal from '../components/notification/NotificationModal';
import HistoricAlertModal from '../components/alert/HistoricAlertModal';

let lastServerTime = 0;
let endTimeRange = new Date().getTime();
let ecg = [{ 'x': (endTimeRange - (C.liveChartsDelay + C.liveChartsStartDomain)), 'y': 0 }];
let imp = [{ 'x': (endTimeRange - (C.liveChartsDelay + C.liveChartsStartDomain)), 'y': 0 }];

function PatientLiveMonitoringDetail({ intl, ...props }) {
    const [timeDomain, setTimeDomain] = useState({ x: [(endTimeRange - (C.liveChartsDelay + C.liveChartsStartDomain)), (endTimeRange - C.liveChartsDelay)] });

    //START CSS
    const [isFullscreenMonitor, setIsFullscreenMonitor] = useState(false);
    const [isConnected, setIsConnected] = useState(false);
    const [isEcgLive, setIsEcgLive] = useState(true);
    const [isImpLive, setIsImpLive] = useState(true);
    //END CSS

    const suhbeader = useSubheader();

    const patientId = props.match.params.id;
    const [selectedPatient, setSelectedPatient] = useState({});

    suhbeader.setTitle(`${intl.formatMessage({ id: 'PAGES.PATIENT_LIVE_MONITORING' })} - ${selectedPatient.firstName} ${selectedPatient.lastName}`);

    const targetRef = useRef();
    const [dimensions, setDimensions] = useState({});
    // holds the timer for setTimeout and clearInterval
    // the number of ms the window size must stay the same size before the
    // dimension state variable is reset
    const resizeDimensions = () => {
        // For some reason targetRef.current.getBoundingClientRect was not available
        // I found this worked for me, but unfortunately I can't find the
        // documentation to explain this experience
        if (targetRef.current) {
            setDimensions({
                width: targetRef.current.offsetWidth,
                height: targetRef.current.offsetHeight,
                ecgCoordinate: parseInt(((targetRef.current.offsetWidth * 60) / 100)),
                impCoordinate: parseInt(((targetRef.current.offsetWidth * 60) / 100) / 10)
            });
        }
    }
    // This sets the dimensions on the first render
    useLayoutEffect(() => {
        resizeDimensions();
    }, []);

    useEffect(() => {
        let resizeEvent = () => setTimeout(resizeDimensions, C.resetTimeoutResizeVictory);
        // every time the window is resized, the timer is cleared and set again
        // the net effect is the component will only reset after the window size
        // is at rest for the duration set in RESET_TIMEOUT.  This prevents rapid
        // redrawing of the component for more complex components such as charts
        window.addEventListener('resize', resizeEvent);
        window.addEventListener('click', resizeEvent);

        const defineSelectedPatient = async () => {
            const data = await (await PatientService.findPatientById(patientId)).json();
            setSelectedPatient(data);
        }

        defineSelectedPatient();
        const socket = LiveService.liveMeasurementsChannel(patientId, onChangeData, onChangeServerTime, onSenseTransmissionOff);

        endTimeRange = new Date().getTime();
        imp = [{ 'x': (endTimeRange - (C.liveChartsDelay + C.liveChartsStartDomain)), 'y': 0 }];
        ecg = [{ 'x': (endTimeRange - (C.liveChartsDelay + C.liveChartsStartDomain)), 'y': 0 }];
        setTimeDomain({ x: [(endTimeRange - (C.liveChartsDelay + C.liveChartsStartDomain)), (endTimeRange - C.liveChartsDelay)] });

        var start = new Date();
        let changeDomainInterval = setInterval(() => {
            const now = new Date();
            const delta = now - start;
            start = now;
            if (endTimeRange < (lastServerTime - C.liveChartsDelay)) {
                //Modifico il range da vedere
                setTimeDomain(oldTimeDomain => { return { x: [(oldTimeDomain.x[0] + delta), (oldTimeDomain.x[1] + delta)] } });
                endTimeRange += delta;
            } else {
                // console.log("RENATO", new Date(endTimeRange).toISOString() + " " + new Date(lastServerTime - C.liveChartsDelay).toISOString() + " " + (endTimeRange - (lastServerTime - C.liveChartsDelay)));
                // console.log("CORVINO", new Date(lastServerTime).toISOString() + " " + now.toISOString() + " " + (now.getTime() - lastServerTime));
            }
        }, C.ecgFrequenzaCambioDominio);

        return () => {
            window.removeEventListener('resize', resizeEvent);
            window.removeEventListener('click', resizeEvent);
            socket.close();
            clearInterval(changeDomainInterval);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);


    const [act, setAct] = useState('-');
    const [br, setBr] = useState('-');
    const [hr, setHr] = useState('-');
    const [speed, setSpeed] = useState('-');
    const [stf, setStf] = useState('-');
    const [stt, setStt] = useState('-');
    const [spo2, setSpo2] = useState('-');
    const [rr, setRr] = useState('-');
    const [weigth, setWeigth] = useState('-');
    const [bloSyst, setBloSyst] = useState('-');
    const [bloDiast, setBloDiast] = useState('-');
    const [bloArr, setBloArr] = useState('-');
    const [hrQi, setHrQi] = useState('-');
    const [brQi, setBrQi] = useState('-');
    const [actQi, setActQi] = useState('-');
    const hrQiGraph = (hrQi === '-') ? 0 : hrQi;
    const brQiGraph = (brQi === '-') ? 0 : brQi;
    const actQiGraph = (actQi === '-') ? 0 : actQi;
    const beatSpeed = (rr === '-') ? 0 : rr;

    const [sttTime, setSttTime] = useState('');
    const [spo2Time, setSpo2Time] = useState('');
    const [weigthTime, setWeigthTime] = useState('');
    const [sphygTime, setSphygTime] = useState('');
    const [hrSat, setHrSat] = useState('-');
    const [arteryCheck, setArteryCheck] = useState('-');
    const [hrGen, setHrGen] = useState('-');
    const [hrMad, setHrMad] = useState('-');
    const [brGen, setBrGen] = useState('-');
    const [rrGen, setRrGen] = useState('-');

    const [serverTime, setServerTime] = useState('');

    let lastHrQi = 0;
    let lastBrQi = 0;
    let lastActQi = 0;

    const onChangeData = (type, payload) => {
        if (!Utils.isEmpty(payload)) {
            // const adesso = new Date();
            const now = new Date(payload.measureTime);
            // console.log(`${adesso.toISOString()} - ${now.toISOString()} = ${new Date() - now}`);
            switch (type) {
                //BLOCCO HEART RATE
                //(HR, RR, ECG)
                case C.MEASUREMENTS_TYPE_HR:
                    if (lastHrQi > 0) {
                        setHr(payload.measureValue);
                    }
                    break;
                case C.MEASUREMENTS_TYPE_RR:
                    if (lastHrQi > 0) {
                        setRr(payload.measureValue);
                    }
                    break;
                case C.MEASUREMENTS_TYPE_ECG:
                    if (lastHrQi > 0) {
                        //Verifico che la trasmissione non sia di dati più vecchi di 5 secondi.
                        if (payload.measureTime + (payload.measureValue.length * C.ecgFrequenzaRilevamento) >= (endTimeRange - (C.liveChartsDelay + C.liveChartsStartDomain))) {
                            //Elimino elementi dell'ecg che ho già visto.
                            setIsEcgLive(true);
                            const minIndex = ecg.findIndex((elem) => elem.x >= (endTimeRange - (C.liveChartsDelay + C.liveChartsStartDomain)))
                            if (minIndex > 0) {
                                ecg.splice(0, (minIndex - 1));
                            }

                            ecg = ecg.concat(payload.measureValue.map((value, i) => {
                                return { 'x': payload.measureTime + (i * C.ecgFrequenzaRilevamento), 'y': value }
                            }));
                        } else {
                            setIsEcgLive(false);
                        }
                    }
                    break;
                //BLOCCO BREATH RATE
                //(BR, IMP)
                case C.MEASUREMENTS_TYPE_BR:
                    if (lastBrQi > 0) {
                        setBr(payload.measureValue);
                    }
                    break;
                case C.MEASUREMENTS_TYPE_IMP:
                    if (lastBrQi > 0) {
                        //Verifico che la trasmissione non sia di dati più vecchi di 5 secondi.
                        if (payload.measureTime >= (endTimeRange - (C.liveChartsDelay + C.liveChartsStartDomain))) {
                            //Elimino elementi dell'impedenza che ho già visto.
                            setIsImpLive(true);
                            const minIndex = imp.findIndex((elem) => elem.x >= (endTimeRange - (C.liveChartsDelay + C.liveChartsStartDomain)))
                            if (minIndex > 0) {
                                imp.splice(0, (minIndex - 1));
                            }

                            imp = imp.concat([{ 'x': payload.measureTime, 'y': payload.measureValue }]);
                        } else {
                            setIsImpLive(false);
                        }
                    }
                    break;
                //BLOCCO ACTIVITY
                //(ACT, SPEED)
                case C.MEASUREMENTS_TYPE_ACT:
                    if (lastActQi > 0) {
                        setAct(payload.measureValue);
                    }
                    break;
                case C.MEASUREMENTS_TYPE_SPEED:
                    if (lastActQi > 0) {
                        setSpeed(payload.measureValue);
                    }
                    break;
                //BLOCCO ON DEMAND
                //(ST_F, ST_T)
                case C.MEASUREMENTS_TYPE_ST_F:
                    setStf(payload.measureValue);
                    break;
                case C.MEASUREMENTS_TYPE_ST_T:
                    setStt(payload.measureValue);
                    setSttTime(dateFormat(now, 'dd/MM/yyyy HH:mm:ss'));
                    break;
                case C.MEASUREMENTS_TYPE_SPO2:
                    setSpo2(payload.measureValue);
                    setSpo2Time(dateFormat(now, 'dd/MM/yyyy HH:mm:ss'));
                    break;
                case C.MEASUREMENTS_TYPE_WEIGHT:
                    setWeigth(payload.measureValue);
                    setWeigthTime(dateFormat(now, 'dd/MM/yyyy HH:mm:ss'));
                    break;
                case C.MEASUREMENTS_TYPE_BLO_SYST:
                    setBloSyst(payload.measureValue);
                    setSphygTime(dateFormat(now, 'dd/MM/yyyy HH:mm:ss'));
                    break;
                case C.MEASUREMENTS_TYPE_BLO_DIAST:
                    setBloDiast(payload.measureValue);
                    setSphygTime(dateFormat(now, 'dd/MM/yyyy HH:mm:ss'));
                    break;
                case C.MEASUREMENTS_TYPE_BLO_ARR:
                    if(payload.measureValue===0) {
                        setBloArr('');
                    } else if(payload.measureValue===1) {
                        setBloArr('ARR');
                    } else if(payload.measureValue===2) {
                        setBloArr('AFIB');
                    } else if(payload.measureValue===3) {
                        setBloArr('PC');
                    }
                    setSphygTime(dateFormat(now, 'dd/MM/yyyy HH:mm:ss'));
                    break;
                //BLOCCO QUALITY INDEX
                //(HR_QI, BR_QI, ACT_QI)
                case C.MEASUREMENTS_TYPE_HR_QI:
                    lastHrQi = payload.measureValue;
                    setHrQi(payload.measureValue);
                    if (payload.measureValue <= 0) {
                        setHr('-');
                        setRr('-');
                        ecg = [{ 'x': (endTimeRange - (C.liveChartsDelay + C.liveChartsStartDomain)), 'y': 0 }];
                    }
                    setIsConnected(lastHrQi > 0 || lastBrQi > 0 || lastActQi > 0);
                    break;
                case C.MEASUREMENTS_TYPE_BR_QI:
                    lastBrQi = payload.measureValue;
                    setBrQi(payload.measureValue);
                    if (payload.measureValue <= 0) {
                        setBr('-');
                        imp = [{ 'x': (endTimeRange - (C.liveChartsDelay + C.liveChartsStartDomain)), 'y': 0 }];
                    }
                    setIsConnected(lastHrQi > 0 || lastBrQi > 0 || lastActQi > 0);
                    break;
                case C.MEASUREMENTS_TYPE_ACT_QI:
                    lastActQi = payload.measureValue;
                    setActQi(payload.measureValue);
                    if (payload.measureValue <= 0) {
                        setAct('-');
                        setSpeed('-');
                    }
                    setIsConnected(lastHrQi > 0 || lastBrQi > 0 || lastActQi > 0);
                    break;
                case C.MEASUREMENTS_TYPE_HR_SAT:
                    setHrSat(payload.measureValue);
                    break;
                case C.MEASUREMENTS_TYPE_ARTERY_CHECK:
                    setArteryCheck(payload.measureValue);
                    break;
                case C.MEASUREMENTS_TYPE_HR_GEN:
                    setHrGen(payload.measureValue);
                    break;
                case C.MEASUREMENTS_TYPE_HR_MAD:
                    setHrMad(payload.measureValue);
                    break;
                case C.MEASUREMENTS_TYPE_BR_GEN:
                    setBrGen(payload.measureValue);
                    break;
                case C.MEASUREMENTS_TYPE_RR_GEN:
                    setRrGen(payload.measureValue);
                    break;
                default:
                    break;
            }
        }
    }

    const onChangeServerTime = (newServerTime) => {
        const diffServerTime = Math.floor(newServerTime / 1000) - Math.floor(lastServerTime / 1000);

        lastServerTime = newServerTime;
        setServerTime(dateFormat(new Date(newServerTime), 'dd/MM/yyyy HH:mm:ss'));
        if (diffServerTime > 1) {
            setTimeDomain({ x: [(newServerTime - (C.liveChartsDelay + C.liveChartsStartDomain)), (newServerTime - C.liveChartsDelay)] });
            endTimeRange = newServerTime - C.liveChartsDelay;
        }
    }

    const onSenseTransmissionOff = () => {
        console.log(`${new Date().toISOString()} / SENSE TRASMISSION OFF`);
        //Resetto tutti i valori
        setIsConnected(false);
        setAct('-');
        setBr('-');
        setHr('-');
        setSpeed('-');
        setStf('-');
        setStt('-');
        setSpo2('-');
        setRr('-');
        setHrQi('-');
        setBrQi('-');
        setActQi('-');
        setHrSat('-');
        setArteryCheck('-');
        setHrGen('-');
        setHrMad('-');
        setBrGen('-');
        setRrGen('-');

        lastHrQi = 0;
        lastBrQi = 0;
        lastActQi = 0;

        ecg = [{ 'x': (endTimeRange - (C.liveChartsDelay + C.liveChartsStartDomain)), 'y': 0 }];
        imp = [{ 'x': (endTimeRange - (C.liveChartsDelay + C.liveChartsStartDomain)), 'y': 0 }];
    }

    //Notification Modal
    const [isOpenNotificationModal, setIsOpenNotificationModal] = useState(false);

    const openNotificationDialog = (patient) => {
        setSelectedPatient(patient);
        setIsOpenNotificationModal(true);
    }

    const closeNotificationDialog = () => {
        setIsOpenNotificationModal(false);
    }
    //Notification Modal

    //HistoricAlert Modal
    const [isOpenAlertModal, setIsOpenAlertModal] = useState(false);

    const openAlertDialog = (patient) => {
        setSelectedPatient(patient);
        setIsOpenAlertModal(true);
    }

    const closeAlertDialog = () => {
        setIsOpenAlertModal(false);
    }
    //HistoricAlert Modal


    return (
        <>
            <BrowserView>
                <div className={`lmContainer ${isFullscreenMonitor ? 'fullScreenMonitor' : ''}`}>
                    {/* HTML DA RIVEDERE */}
                    <div className="lmTopBar">
                        <div>
                            <div className="select">
                                <select>
                                    <option>{selectedPatient.firstName} {selectedPatient.lastName}</    option>
                                    {/* <option>Antonio Fiorillo</option> */}
                                    {/* <option>Vincenzo Salerno</option>
                                <option>Carlo Sperandeo</option>
                                <option>Sabato Limonciello</option> */}
                                </select>
                                <div className="select-arrow"></div>
                            </div>
                        </div>

                        <div className="lmServerTime">
                            <span className="barMonitoredLabel font-weight-bold">{serverTime}</span>
                        </div>
                        <div className="lmWarningContainer">
                            <div className="lmWarningDefault"></div>
                        </div>
                        <div className="lmTopBarActionMenu">
                            <div className="barNavTime align-items-center" ><SVG src={toAbsoluteUrl("/media/svg/icons/Custom/Live/Status.svg")} /></div>
                            <div className="barNavTime align-items-center" ><SVG src={toAbsoluteUrl("/media/svg/icons/Custom/Live/BatteryFull.svg")} /></div>
                            <div className="barNavTime align-items-center" onClick={() => { setIsFullscreenMonitor(!isFullscreenMonitor); }}><SVG src={toAbsoluteUrl("/media/svg/icons/Custom/Live/Expand.svg")} /></div>
                        </div>
                    </div>
                    <div className="lmMainArea">
                        <div className="lmGraphBox" ref={targetRef}>
                            {isConnected ?
                                // isEcgLive ? (
                                //     <VictoryLine
                                //         padding={{ top: 20, bottom: 20, right: 20, left: 20 }}
                                //         height={dimensions.height}
                                //         width={dimensions.width}
                                //         domain={timeDomain}
                                //         containerComponent={<VictoryContainer responsive={false} />}
                                //         style={{ data: { stroke: "#02FC02", strokeWidth: 2 } }}
                                //         data={ecg} />
                                // ) : (
                                //         <div style={{ padding: '50px' }}>
                                //             <h1 className="text-white">MEASURE IN THE PAST</h1>
                                //         </div>
                                //     )
                                <VictoryLine
                                        padding={{ top: 20, bottom: 20, right: 20, left: 20 }}
                                        height={dimensions.height}
                                        width={dimensions.width}
                                        domain={timeDomain}
                                        containerComponent={<VictoryContainer responsive={false} />}
                                        style={{ data: { stroke: "#02FC02", strokeWidth: 2 } }}
                                        data={ecg} />
                                : (
                                    <div style={{ padding: '50px' }}>
                                        <h1 className="text-white">SENSOR DISCONNECTED</h1>
                                    </div>
                                )}
                        </div>
                        <div className="lmGraphBox" ref={targetRef}>
                            {isConnected ?
                                // isImpLive ? (
                                //     <VictoryLine
                                //         padding={{ top: 20, bottom: 20, right: 20, left: 20 }}
                                //         height={dimensions.height}
                                //         width={dimensions.width}
                                //         domain={timeDomain}
                                //         containerComponent={<VictoryContainer responsive={false} />}
                                //         style={{ data: { stroke: "#FAFA04", strokeWidth: 2 } }}
                                //         data={imp} />
                                // ) : (
                                //         <div style={{ padding: '50px' }}>
                                //             <h1 className="text-white">MEASURE IN THE PAST</h1>
                                //         </div>
                                //     )
                                <VictoryLine
                                        padding={{ top: 20, bottom: 20, right: 20, left: 20 }}
                                        height={dimensions.height}
                                        width={dimensions.width}
                                        domain={timeDomain}
                                        containerComponent={<VictoryContainer responsive={false} />}
                                        style={{ data: { stroke: "#FAFA04", strokeWidth: 2 } }}
                                        data={imp} />
                                     : (
                                    <div style={{ padding: '50px' }}>
                                        <h1 className="text-white">SENSOR DISCONNECTED</h1>
                                    </div>
                                )}
                        </div>

                        <div className="lmGraphBox d-flex lmGraphBoxOverflow" ref={targetRef}>

                            {/* lmBorderWARNING */}
                            <div className="lmMeasureContainerRow colorOrange ">
                                <div className="lmMeasureCol">
                                    <div>
                                        <div className="lmMeasureTitle">
                                            <FormattedMessage id="MEASURE.TITLE.BODY_TEMP" />
                                        </div>
                                        39<br />36
                                </div>
                                    <div>
                                        <SVG className="fillOrange" src={toAbsoluteUrl("/media/svg/icons/Custom/HistoricMonitoring-Temperature.svg")} />
                                    </div>
                                </div>
                                <div className="lmMeasureCol2">
                                    <div className="lmMeasureUnit">
                                        &#8451;
                                </div>
                                    <div className="lmMeasureParameter" style={{ fontSize: '5rem', lineHeight: '8rem' }}>
                                        {stt}
                                    </div>
                                    <div style={{ position: 'absolute', bottom: '21px', left: '55px' }}>{sttTime}</div>
                                </div>
                                <div className="lmMeasureCol3">
                                    <div>
                                        <span className="lmMeasureTitle2">
                                            <FormattedMessage id="MEASURE.TITLE.SKIN_TEMP" />
                                        </span>&#8451;
                                </div>
                                    <div className="lmMeasureParameter2">
                                        {stf}
                                    </div>
                                </div>
                            </div>
                            <div className="lmMeasureContainerRow colorOrange ">
                                <div className="lmMeasureCol">
                                    <div>
                                        <div className="lmMeasureTitle">
                                            <FormattedMessage id="MEASURE.TITLE.WEIGHT" />
                                        </div>
                                        {/* 39<br />36 */}
                                </div>
                                    <div>
                                        <SVG className="fillOrange" src={toAbsoluteUrl("/media/svg/icons/Home/Weight2.svg")} />
                                    </div>
                                </div>
                                <div className="lmMeasureCol2">
                                    <div className="lmMeasureUnit">
                                        Kg
                                </div>
                                    <div className="lmMeasureParameter" style={{ fontSize: '5rem', lineHeight: '8rem' }}>
                                        {weigth}
                                    </div>
                                    <div style={{ position: 'absolute', bottom: '21px', left: '55px' }}>{weigthTime}</div>
                                </div>
                            </div>
                            <div className="lmMeasureContainerRow colorOrange ">
                                <div className="lmMeasureCol">
                                    <div>
                                        <div className="lmMeasureTitle">
                                            <FormattedMessage id="MEASURE.TITLE.BLOOD_PRESSURE" />
                                        </div>
                                        {bloArr}
                                </div>
                                    <div>
                                        <SVG style={{width: '42px'}} className="fillOrange" src={toAbsoluteUrl("/media/svg/icons/Custom/Sphygmomanometer.svg")} />
                                    </div>
                                </div>
                                <div className="lmMeasureCol2">
                                    <div className="lmMeasureUnit">
                                    mmHg
                                </div>
                                    <div className="lmMeasureParameter" style={{ fontSize: '4rem', lineHeight: '7rem' }}>
                                        {bloSyst}/{bloDiast}
                                    </div>
                                    <div style={{ position: 'absolute', bottom: '21px', left: '55px' }}>{sphygTime}</div>
                                </div>
                            </div>

                            {/* lmBorderWARNING */}
                            <div className="lmMeasureContainerRow colorRed ">

                            </div>

                            {/* lmBorderWARNING */}
                            <div className="lmMeasureContainerRow colorRed">

                            </div>

                        </div>
                    </div>
                    <div className="lmRightArea">

                        {/* lmBorderWARNING */}
                        <div className="lmMeasureContainer colorGreen ">
                            <div className="lmMeasureCol">
                                <div>
                                    <div className="lmMeasureTitle">
                                        <FormattedMessage id="MEASURE.TITLE.HR" />
                                    </div>
                                    <div className="d-flex flex-column">
                                        <div className="d-flex justify-content-between">
                                            {hrGen}
                                            {/* <p className="text-light">gen</p> */}
                                        </div>
                                        <div className="d-flex justify-content-between">
                                            {hrMad}
                                            {/* <p className="text-light">med</p> */}
                                        </div>
                                    </div>
                                </div>
                                <div style={{ animation: 'blinker ' + beatSpeed + 'ms linear infinite' }}>
                                    <SVG src={toAbsoluteUrl("/media/svg/icons/Custom/Live/ECG-Heart.svg")} />
                                </div>
                            </div>
                            <div className="lmMeasureCol2">
                                <div className="lmMeasureUnit">
                                    <FormattedMessage id="MEASURE.MEASURE_UNIT.BPM" />
                                </div>
                                <div className="lmMeasureParameter">
                                    {hr}
                                </div>
                            </div>
                            <div className="lmMeasureCol3">
                                <div className="d-flex flex-column">
                                    {rrGen}
                                    <span className="lmMeasureTitle2">
                                        <FormattedMessage id="MEASURE.TITLE.RR" />
                                    </span><FormattedMessage id="MEASURE.MEASURE_UNIT.MS" />
                                </div>
                                <div className="lmMeasureParameter2">
                                    {rr}
                                </div>
                            </div>
                            <div className="lmMeasureCol4">
                                <div className="lmMeasureCol1">
                                    <FormattedMessage id="MEASURE.TITLE.HR_QI" />
                                </div>
                                <div className="lmMeasureCol1">
                                    <FormattedMessage id="MEASURE.MEASURE_UNIT.%" />
                                </div>
                                <div className="lmIQContainer">
                                    {/* <div className="lmWhiteIQFill" style={{ height: hrQiGraph + '%'}}></div> */}
                                    <div className={`${Utils.getQiClassByLevel(hrQi)}`} style={{ height: hrQiGraph + '%' }}></div>
                                    <div className="lmIQRail" style={{ backgroundImage: "url(" + toAbsoluteUrl(Utils.getQiImageByLevel(hrQi)) + ")" }}></div>
                                </div>
                                <div className="lmMeasureCol1">
                                    {hrQi}
                                </div>
                            </div>
                        </div>

                        {/* lmBorderWARNING */}
                        <div className="lmMeasureContainer colorYellow ">
                            <div className="lmMeasureCol">
                                <div>
                                    <div className="lmMeasureTitle">
                                        <FormattedMessage id="MEASURE.TITLE.BR" />
                                    </div>
                                    <div className="d-flex flex-column">
                                        <div className="d-flex justify-content-between">
                                            {brGen}
                                            {/* <p>gen</p> */}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="lmMeasureCol2">
                                <div className="lmMeasureUnit">
                                    <FormattedMessage id="MEASURE.MEASURE_UNIT.BREATHS_MIN" />
                                </div>
                                <div className="lmMeasureParameter">
                                    {br}
                                </div>
                            </div>
                            <div className="lmMeasureCol3"></div>
                            <div className="lmMeasureCol4">
                                <div className="lmMeasureCol1">
                                    <FormattedMessage id="MEASURE.TITLE.HR_QI" />
                                </div>
                                <div className="lmMeasureCol1">
                                    <FormattedMessage id="MEASURE.MEASURE_UNIT.%" />
                                </div>
                                <div className="lmIQContainer">
                                    <div className={`${Utils.getQiClassByLevel(brQi)}`} style={{ height: brQiGraph + '%' }}></div>
                                    <div className="lmIQRail" style={{ backgroundImage: "url(" + toAbsoluteUrl(Utils.getQiImageByLevel(brQi)) + ")" }}></div>
                                </div>
                                <div className="lmMeasureCol1">
                                    {brQi}
                                </div>
                            </div>
                        </div>

                        {/* lmBorderWARNING */}
                        <div className="lmMeasureContainer colorCyan   ">
                            <div className="lmMeasureCol">
                                <div>
                                    <div className="lmMeasureTitle">
                                        <FormattedMessage id="MEASURE.TITLE.SPO2" />
                                    </div>
                                    {hrSat}<br />{arteryCheck}
                                </div>
                            </div>
                            <div className="lmMeasureCol2">
                                <div className="lmMeasureUnit">
                                    <FormattedMessage id="MEASURE.MEASURE_UNIT.%" />
                                </div>
                                <div className="lmMeasureParameter">
                                    {spo2}
                                </div>
                            </div>
                            <div className="lmMeasureCol3">{spo2Time}</div>
                            <div className="lmMeasureCol4"></div>
                        </div>

                        {/* lmBorderWARNING */}
                        <div className="lmMeasureContainer colorPurple ">
                            <div className="lmMeasureCol">
                                <div>
                                    <div className="lmMeasureTitle">
                                        <FormattedMessage id="MEASURE.TITLE.ACTIVITY" />
                                    </div>
                                    {act === '-' ? '-' : intl.formatMessage({ id: `MEASURE.TYPE_ACTIVITY_` + act })}
                                </div>
                            </div>
                            <div className="lmMeasureCol2">
                                <div className="lmIconParameter">
                                    {Utils.getActivityIcon(act)}
                                </div>
                            </div>
                            <div className="lmMeasureCol3">
                                <div>
                                    <FormattedMessage id="MEASURE.TITLE.SPEED" />
                                    <br /><FormattedMessage id="MEASURE.MEASURE_UNIT.KM_H" />
                                </div>
                                <div className="lmMeasureParameter2">
                                    {speed}
                                </div>
                            </div>
                            <div className="lmMeasureCol4">
                                <div className="lmMeasureCol1">
                                    <FormattedMessage id="MEASURE.TITLE.HR_QI" />
                                </div>
                                <div className="lmMeasureCol1">
                                    <FormattedMessage id="MEASURE.MEASURE_UNIT.%" />
                                </div>
                                <div className="lmIQContainer">
                                    <div className={`${Utils.getQiClassByLevel(actQi)}`} style={{ height: actQiGraph + '%' }}></div>
                                    <div className="lmIQRail" style={{ backgroundImage: "url(" + toAbsoluteUrl(Utils.getQiImageByLevel(actQi)) + ")" }}></div>
                                </div>
                                <div className="lmMeasureCol1">
                                    {actQi}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="lmSideControl">
                        <button className="lmSideButton"><SVG src={toAbsoluteUrl("/media/svg/icons/Custom/Live/Chart-View.svg")} style={{ marginLeft: '3px', height: '43px', width: '43px' }} /></button>
                        <button className="lmSideButton"><SVG src={toAbsoluteUrl("/media/svg/icons/Custom/Live/Grid-View.svg")} style={{ marginLeft: '3px' }} /></button>
                        <button className="lmSideButton"><SVG src={toAbsoluteUrl("/media/svg/icons/Custom/Live/Historic-Alert.svg")} style={{ marginLeft: '3px' }} /></button>
                        <button className="lmSideButton" onClick={() => { openNotificationDialog(selectedPatient) }}><SVG src={toAbsoluteUrl("/media/svg/icons/Custom/Live/Notification.svg")} style={{ marginLeft: '6px', height: '30px', width: '30px' }} /></button>
                        <button className="lmSideButton" onClick={() => { openAlertDialog(selectedPatient) }}><SVG src={toAbsoluteUrl("/media/svg/icons/Custom/Live/Notification-Alert.svg")} style={{ marginLeft: '3px' }} /></button>
                        <button className="lmSideButton" onClick={() => { props.voipCall(selectedPatient) }}><SVG src={toAbsoluteUrl("/media/svg/icons/Custom/Live/VOIP.svg")} /></button>
                        {/* <button className="lmSideButton"><SVG src={toAbsoluteUrl("/media/svg/icons/Map/Compass.svg")} /></button> */}
                        <button className="lmSideButton">
                            <NavLink title="Monitoraggio Live" to={`/patient-live-monitoring`}>
                                <SVG src={toAbsoluteUrl("/media/svg/icons/Custom/Live/PowerOff.svg")} style={{ marginLeft: '3px' }} />
                            </NavLink>
                        </button>
                    </div>
                </div>

                <NotificationModal key={`notificationModal-${isOpenNotificationModal}`} isModalOpen={isOpenNotificationModal} handleClose={closeNotificationDialog} selectedPatient={selectedPatient} />
                <HistoricAlertModal key={`alertyModal-${isOpenAlertModal}`} isModalOpen={isOpenAlertModal} handleClose={closeAlertDialog} selectedPatient={selectedPatient} />
            </BrowserView>
            <MobileView>
                <div style={{ backgroundColor: 'black', borderRadius: '5px' }}>
                    <div style={{ height: '36px', borderBottom: '1px solid white' }}>
                        <div className="select w-100">
                            <select style={{ borderRight: 'none', borderTopRightRadius: '5px' }}>
                                <option>{selectedPatient.firstName} {selectedPatient.lastName}</    option>
                                {/*
                                    <option>Antonio Fiorillo</option>
                                    <option>Vincenzo Salerno</option>
                                    <option>Carlo Sperandeo</option>
                                    <option>Sabato Limonciello</option>
                                */}
                            </select>
                            <div className="select-arrow"></div>
                        </div>
                    </div>
                    <div style={{ height: '40px', borderBottom: '1px solid white' }}>
                        <div style={{ display: 'flex', float: 'left', paddingTop: '7px', paddingLeft: '5px', color: 'white' }}>
                            <SVG src={toAbsoluteUrl("/media/svg/icons/Custom/Live/MobileLiveServerTimeIcon.svg")} style={{ marginRight: '18px' }} />
                            <div style={{ marginTop: '3px' }} >{serverTime}</div>
                        </div>
                        <div className="d-flex float-right">
                            <div style={{ width: '50px', height: '39px', borderLeft: '1px solid white', padding: '5px' }}>
                                {/* lmGeneralStatusDANGERMobile oppure lmGeneralStatusWARNINGMobile */}
                                <div className="">

                                </div>
                            </div>
                            <div style={{ width: '50px', height: '39px', borderLeft: '1px solid white' }}>
                                {/* TO DO, se aggiorniamo la pagina ritorna sulla vista principale */}
                                <NavLink title="Monitoraggio Live" to={`/patient-live-monitoring`}>
                                    <SVG src={toAbsoluteUrl("/media/svg/icons/Custom/Live/PowerOff.svg")} style={{ marginLeft: '10px', marginTop: '2px' }} />
                                </NavLink>
                            </div>
                        </div>
                    </div>
                    <div style={{ height: '40px', borderBottom: '1px solid white' }}>
                        <div style={{ display: 'flex', float: 'left', paddingTop: '9px', paddingLeft: '5px', color: 'white' }}>
                            <SVG src={toAbsoluteUrl("/media/svg/icons/Custom/Live/MobileLiveMonitoredForIcon.svg")} style={{ marginRight: '10px' }} />
                            <div style={{ marginTop: '3px' }} >Covid 19</div>
                        </div>
                        <div className="d-flex float-right">
                            <div style={{ width: '50px', height: '39px', borderLeft: '1px solid white' }}>
                                <SVG src={toAbsoluteUrl("/media/svg/icons/Custom/Live/Notification-Alert.svg")} style={{ marginTop: '5px', marginLeft: '12px' }} />
                            </div>
                            <div style={{ width: '50px', height: '39px', borderLeft: '1px solid white' }}>
                                <SVG src={toAbsoluteUrl("/media/svg/icons/Custom/Live/BatteryFull.svg")} style={{ width: '50px', height: '30px', margin: '4px 0 0 2px' }} />
                            </div>
                        </div>

                    </div>

                    <div style={{ height: '196px', borderBottom: '1px solid white' }}>
                        {/* <div className="lmBorderWARNINGMobile"> */}
                        <div className="">
                            <div style={{ height: '120px' }} ref={targetRef}>
                                {isConnected ? (
                                    <VictoryLine
                                        padding={{ top: 10, bottom: 10, right: 10, left: 5 }}
                                        height={dimensions.height}
                                        width={dimensions.width}
                                        domain={timeDomain}
                                        containerComponent={<VictoryContainer responsive={false} />}
                                        style={{ data: { stroke: "#02FC02", strokeWidth: 2 } }}
                                        data={ecg} />
                                ) : (
                                        <div style={{ padding: '50px' }}>
                                            <h1 className="text-white">SENSOR DISCONNECTED</h1>
                                        </div>
                                    )}
                            </div>
                            <div className="lmMeasureHRMobile colorGreen">
                                {/* HR */}
                                <div className="lmMeasureColMobile justify-content-between">
                                    <div>
                                        <div className="lmMeasureTitle1Mobile">
                                            <FormattedMessage id="MEASURE.TITLE.HR" />
                                        </div>
                                        {hrGen} <FormattedMessage id="MEASURE.MEASURE_UNIT.BPM" />
                                    </div>
                                </div>
                                <div className="lmMeasureColMobile lmColHR">
                                    <div className="lmMeasureParameterMobile">
                                        <div>
                                            {hr}
                                        </div>
                                        <div className="hrContainerIconMobile">
                                            <div className="hrIconMobile" style={{ animation: 'blinker ' + beatSpeed + 'ms linear infinite' }}>
                                                <SVG src={toAbsoluteUrl("/media/svg/icons/Custom/Live/ECG-Heart.svg")} />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="lmMeasureColMobile">
                                    <div>
                                        <span className="lmMeasureTitle1Mobile">
                                            <FormattedMessage id="MEASURE.TITLE.RR" />
                                        </span><FormattedMessage id="MEASURE.MEASURE_UNIT.MS" />
                                    </div>
                                    <div className="lmMeasureParameter2">
                                        {rr}
                                    </div>
                                </div>
                                <div className="lmQIColMobile">
                                    <div className="lmMeasureCol1 mr-2">
                                        <FormattedMessage id="MEASURE.TITLE.HR_QI" />
                                        <br />
                                        {hrQi} <FormattedMessage id="MEASURE.MEASURE_UNIT.%" />
                                    </div>
                                    <div className="lmIQContainer">
                                        {/* <div className="lmWhiteIQFill" style={{ height: hrQiGraph + '%'}}></div> */}
                                        <div className={`${Utils.getQiClassByLevel(hrQi)}`} style={{ height: hrQiGraph + '%' }}></div>
                                        <div className="lmIQRail" style={{ backgroundImage: "url(" + toAbsoluteUrl(Utils.getQiImageByLevel(hrQi)) + ")" }}></div>
                                    </div>
                                </div>
                                {/* HR */}
                            </div>
                        </div>
                    </div>
                    <div style={{ height: '200px', borderBottom: '1px solid white' }}>
                        <div style={{ height: '120px' }} ref={targetRef}>
                            {isConnected ? (
                                <VictoryLine padding={{ top: 10, bottom: 10, right: 10, left: 5 }} height={dimensions.height} width={dimensions.width} domain={{ x: [0, dimensions.impCoordinate] }} containerComponent={<VictoryContainer responsive={false} />} style={{ data: { stroke: "#FAFA04", strokeWidth: 2 } }} data={imp} />
                            ) : (
                                    <div style={{ padding: '50px' }}>
                                        <h1 className="text-white">SENSOR DISCONNECTED</h1>
                                    </div>
                                )}
                        </div>

                        <div className="lmMeasureContainerMobile">
                            {/* RESP */}
                            {/* <div className="lmPar3Cols lmBorderWARNINGMobile"> */}
                            <div className="lmPar3Cols">
                                <div className="lmMeasureColMobile colorYellow">
                                    <div>
                                        <div className="lmMeasureTitle2Mobile">
                                            <FormattedMessage id="MEASURE.TITLE.BR" />
                                        </div>
                                        8<br />30 <FormattedMessage id="MEASURE.MEASURE_UNIT.BREATHS_MIN" />
                                    </div>
                                </div>
                                <div className="lmMeasureColMobile colorYellow">
                                    <div className="lmMeasureParameterMobile">
                                        <div>
                                            {br}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {/* FINE RESP */}

                            {/* SPO2 */}
                            {/* <div className="lmPar3Cols lmBorderWARNINGMobile"> */}
                            <div className="lmPar3Cols">
                                <div className="lmMeasureColMobile colorCyan">
                                    <div>
                                        <div className="lmMeasureTitle2Mobile">
                                            <FormattedMessage id="MEASURE.TITLE.SPO2" />
                                        </div>
                                        {hrSat}<br />{arteryCheck}
                                        <br />
                                    </div>
                                </div>
                                <div className="lmMeasureColMobile colorCyan">
                                    <div className="lmMeasureParameterMobile">
                                        <div>
                                            {spo2}
                                        </div>
                                    </div>
                                    <div className="text-right mt-2">
                                        {spo2Time}
                                    </div>
                                </div>
                            </div>
                            {/* FINE SPO2 */}

                            {/* ACTIVITY */}
                            {/* <div className="lmPar3Cols lmBorderWARNINGMobile"> */}
                            <div className="lmPar3Cols">
                                <div className="lmMeasureColMobile colorPurple">
                                    <div style={{ marginRight: '3px' }}>
                                        <div className="lmMeasureTitle2Mobile">
                                            <FormattedMessage id="MEASURE.TITLE.ACTIVITY" />
                                        </div>
                                        {act === '-' ? '-' : intl.formatMessage({ id: `MEASURE.TYPE_ACTIVITY_` + act })}
                                    </div>
                                </div>
                                <div className="lmMeasureColMobile colorPurple">
                                    {/* <div className="lmMeasureParameterMobile"> */}
                                    <div className="">
                                        <div>
                                            <FormattedMessage id="MEASURE.TITLE.SPEED" />
                                            <br /><FormattedMessage id="MEASURE.MEASURE_UNIT.KM_H" />
                                        </div>
                                        <div className="lmMeasureParameter2">
                                            {speed}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {/* FINE ACTIVITY */}
                        </div>
                    </div>

                    <div style={{ height: '78px', borderBottom: '1px solid white' }}>
                        <div className="lmMeasureContainerMobile">

                            {/* TBODY */}
                            {/* <div className="lmPar2Cols lmBorderWARNINGMobile"> */}
                            <div className="lmPar2Cols ">
                                <div className="lmMeasureColMobile colorOrange">
                                    <div>
                                        <div className="lmMeasureTitle2Mobile">
                                            <FormattedMessage id="MEASURE.TITLE.BODY_TEMP" /> &#8451;
                                        </div>
                                        39<br />36
                                    </div>
                                </div>
                                <div className="lmMeasureCol2Mobile">
                                    <div className="lmMeasureParameterMobile colorOrange">
                                        <div>
                                            {stt}
                                        </div>
                                    </div>
                                    <div className="text-right mt-2 colorOrange">
                                        {sttTime}
                                    </div>
                                </div>
                            </div>
                            {/* FINE TBODY */}

                            {/* TSKIN */}
                            {/* <div className="lmPar2Cols lmBorderWARNINGMobile"> */}
                            <div className="lmPar2Cols ">
                                <div className="lmMeasureColMobile colorOrange">
                                    <div>
                                        <div className="lmMeasureTitle2Mobile">
                                            <FormattedMessage id="MEASURE.TITLE.SKIN_TEMP" /> &#8451;
                                        </div>
                                        {/* 8<br />30 */}
                                    </div>
                                </div>
                                <div className="lmMeasureCol2Mobile">
                                    <div className="lmMeasureParameterMobile colorOrange">
                                        <div>
                                            {stf}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {/* FINE TSKIN */}
                        </div>
                    </div>
                    <div style={{ height: '78px', borderBottom: '1px solid white' }}>
                        <div className="lmMeasureContainerMobile">

                            {/* TBODY */}
                            {/* <div className="lmPar2Cols lmBorderWARNINGMobile"> */}
                            <div className="lmPar2Cols ">
                                <div className="lmMeasureColMobile colorOrange">
                                    <div>
                                        <div className="lmMeasureTitle2Mobile">
                                            <FormattedMessage id="MEASURE.TITLE.WEIGHT" /> &#8451;
                                        </div>
                                        {/* 39<br />36 */}
                                    </div>
                                </div>
                                <div className="lmMeasureCol2Mobile">
                                    <div className="lmMeasureParameterMobile colorOrange">
                                        <div>
                                            {weigth}
                                        </div>
                                    </div>
                                    <div className="text-right mt-2 colorOrange">
                                        {weigthTime}
                                    </div>
                                </div>
                            </div>
                            {/* FINE TBODY */}
                        </div>
                    </div>
                    <div style={{ height: '78px', borderBottom: '1px solid white' }}>
                        <div className="lmMeasureContainerMobile">

                            {/* TBODY */}
                            {/* <div className="lmPar2Cols lmBorderWARNINGMobile"> */}
                            <div className="lmPar2Cols ">
                                <div className="lmMeasureColMobile colorOrange">
                                    <div>
                                        <div className="lmMeasureTitle2Mobile">
                                            <FormattedMessage id="MEASURE.TITLE.BLOOD_PRESSURE" /> &#8451;
                                        </div>
                                            {bloArr}
                                    </div>
                                </div>
                                <div className="lmMeasureCol2Mobile">
                                    <div className="lmMeasureParameterMobile colorOrange">
                                        <div>
                                        {bloSyst}/{bloDiast}
                                        </div>
                                    </div>
                                    <div className="text-right mt-2 colorOrange">
                                        {sphygTime}
                                    </div>
                                </div>
                            </div>
                            {/* FINE TBODY */}
                        </div>
                    </div>
                </div>
            </MobileView>
        </>
    );
}

export default injectIntl(PatientLiveMonitoringDetail);