export const rowsPerPage                = 25;
export const lastHoursMeasurement       = 8;
export const hoursPerOneDay             = 24;
export const maxEcgCoordinate           = 900;
export const maxImpCoordinate           = 90;
export const minEcgYZoomDomain           = 0;
export const maxEcgYZoomDomain           = 500;
export const YEcgZoomDomain              = [minEcgYZoomDomain, maxEcgYZoomDomain];
export const minImpYZoomDomain           = 0;
export const maxImpYZoomDomain           = 500;
export const YImpZoomDomain              = [minImpYZoomDomain, maxImpYZoomDomain];

export const valueRefMinHR               = 30;
export const valueRefMedHR               = 90;
export const valueRefMaxHR               = 110;
export const valueRefMinBR               = 3;
export const valueRefMedBR               = 15;
export const valueRefMaxBR               = 20;
export const valueRefMinSpO2             = 88;
export const valueRefMedSpO2             = 92;
export const valueRefMaxSpO2             = 100;
export const valueRefMinSTT              = 34;
export const valueRefMedSTT              = 37;
export const valueRefMaxSTT              = 38;

export const yScaleHR                   = [40, 200];
export const yScaleBR                   = [5, 40];
export const yScaleSpo2                 = [83, 100];
export const yScaleSTT                  = [34, 42];
export const yScaleECG                  = [-300, 600];
export const yScaleIMP                  = [-300, 300];

export const liveChartsDelay                = 10000;
export const liveChartsStartDomain          = 5000;
export const ecgFrequenzaRilevamento        = 9;
export const ecgFrequenzaCambioDominio      = 30;

export const timeMeasureInSeconds       = 30;
export const resetTimeoutResizeVictory = 100;

//Roles
export const ROLE_ADMIN                 = "ADMIN"
export const ROLE_DOC                   = "DOCTOR"

//Status
export const STATUTS_200                = 200
export const STATUTS_201                = 201
export const STATUTS_401                = 401
export const STATUTS_404                = 404

//Path
export const PATH_PARAM_ID              = ":id"

export const PATH_ALL                   = "*"
export const PATH_NONE                  = "/"
export const PATH_LOGIN                 = "/login"
export const PATH_DASHBOARD             = "/dashboard"
export const PATH_DOCTOR_LIST           = "/doctorList"
export const PATH_DOCTOR_EDIT           = "/doctorEdit/"
export const PATH_DOCTOR_NEW            = "/doctorNew"
export const PATH_PATIENT_LIST          = "/patientList"
export const PATH_PATIENT_EDIT          = "/patientEdit/"
export const PATH_PATIENT_NEW           = "/patientNew"
export const PATH_PATIENT_MEASUREMENTS  = "/patientMeasurements/"
export const PATH_PATIENT_LIVE_MEASURE  = "/patientLiveMeasure/"
export const PATH_PATIENT_CARD          = "/patientCard/"
export const PATH_PATIENT_COVID_CARD    = "/patientCovidCard/"

//Methods
export const METHOD_GET                 = "GET"
export const METHOD_POST                = "POST"
export const METHOD_PUT                 = "PUT"
export const METHOD_DELETE              = "DELETE"

//ENVIRONMENT
export const ENVIRONMENT                = process.env.NODE_ENV === 'development' ? process.env.REACT_APP_ENVIRONMENT : window.REACT_APP_ENVIRONMENT;

//Backend URL
export const BACKEND_URL                = process.env.NODE_ENV === 'development' ? process.env.REACT_APP_API_URL : window.REACT_APP_API_URL;

//Websocket URL
export const WS_URL                     = process.env.NODE_ENV === 'development' ? process.env.REACT_APP_WS_URL : window.REACT_APP_WS_URL;

//API
export const API_LOGIN                                  = "/api/login/doctor"
export const API_DASHBOARD                              = "/api/dashboard"
export const API_COUNT_ENABLED_PATIENTS                 = "/countEnabledPatient"
export const API_COUNT_PATIENTS_ON_AIR                  = "/countPatientsOnAir"
export const API_COUNT_PATIENTS_LAST_MEASURE_OLD        = "/countPatientsLastMeasureOld"
export const API_COUNT_PATIENTS_TYPE_MONITORING         = "/countPatientsTypeMonitoring"
export const API_COUNT_PATIENTS_HIGH_FEVER              = "/countPatientsHighFever"
export const API_COUNT_PATIENTS_LOW_OXYGEN_SATURATION   = "/countPatientsLowOxygenSaturation"
export const API_DOCTOR                                 = "/api/doctor"
export const API_PATIENT                                = "/api/patient"
export const API_NOTIFICATION                           = "/notification"
export const API_MEASUREMENT                            = "/measure"
export const API_MEASURE_MONITORED                      = "/measureMonitored"
export const API_MEASURE                                = "/measure"
// export const LAST_SATURATION                            = "/lastSaturation"
export const API_PROVINCE                               = "/api/comuni/provincia"
export const API_COMUNI                                 = "/api/comuni/comune"
export const API_FARMACI                                = "/api/farmaci"
export const API_DIAGNOSI                               = "/api/diagnosi"
export const API_INTERVENTI                             = "/api/interventi"
export const API_ENABLING_CHANGE                        = "/enablingChange"
export const API_COVID_EXAM                             = "/api/covidExam"
export const API_LAST_COVID_EXAM                        = "/lastCovidExam"
export const API_IDS_ON_AIR                             = "/idsOnAir"
export const API_ALERT                                  = "/api/alert"
export const API_ALERT_SETTINGS                         = "/api/parameter/alert"
export const API_ALERT_LIST                             = "/api/alert/list"

//TYPE MONITORING
export const TYPE_MONITORING_C19        = "C19"
export const TYPE_MONITORING_IRES       = "IRES"
export const TYPE_MONITORING_ICAR       = "ICAR"

//TYPE COVID EXAM
export const TYPE_COVID_EXAM_TSR        = "TSR"
export const TYPE_COVID_EXAM_IGC        = "IGC"
export const TYPE_COVID_EXAM_IGM        = "IGM"
export const TYPE_COVID_EXAM_TMP        = "TMP"

//OUTCOME COVID EXAM
export const TYPE_COVID_EXAM_NEGATIVE   = "N"
export const TYPE_COVID_EXAM_POSITIVE   = "P"

//DASHBOARD_TYPE
export const DASHBOARD_PATIENT_ENABLED          = "patientEnabled"
export const DASHBOARD_PATIENT_ON_AIR           = "patientOnAir"
export const DASHBOARD_PATIENT_LAST_MEASURE_OLD = "patientLastMeasureOld"
export const DASHBOARD_PATIENT_HIGH_TEMP        = "patientHighTemp"
export const DASHBOARD_PATIENT_LOW_SATURATION   = "patientLowSaturation"
export const DASHBOARD_PATIENT_MONITORING_TYPES = "patientMonitoringTypes"
export const DASHBOARD_PATIENT_ALERT_PARAMETERS = "alertParameters"


//
export const MEASUREMENTS_TYPE_ACT              = "ACT"
export const MEASUREMENTS_TYPE_BR               = "BR"
export const MEASUREMENTS_TYPE_ECG              = "ECG"
export const MEASUREMENTS_TYPE_HR               = "HR"
export const MEASUREMENTS_TYPE_IMP              = "IMP"
export const MEASUREMENTS_TYPE_SPEED            = "SPEED"
export const MEASUREMENTS_TYPE_SPO2             = "SpO2"
export const MEASUREMENTS_TYPE_ST_F             = "ST_F"
export const MEASUREMENTS_TYPE_ST_T             = "ST_T"
export const MEASUREMENTS_TYPE_GENERIC_ST       = "ST"
export const MEASUREMENTS_TYPE_RR               = "RR"
export const MEASUREMENTS_TYPE_HR_QI            = "HR_QI"
export const MEASUREMENTS_TYPE_BR_QI            = "BR_QI"
export const MEASUREMENTS_TYPE_ACT_QI           = "ACT_QI"
export const MEASUREMENTS_TYPE_HR_SAT           = "HR_SAT"
export const MEASUREMENTS_TYPE_ARTERY_CHECK     = "ARTERY_CHECK"
export const MEASUREMENTS_TYPE_HR_GEN           = "HR_GEN"
export const MEASUREMENTS_TYPE_HR_MAD           = "HR_MAD"
export const MEASUREMENTS_TYPE_BR_GEN           = "BR_GEN"
export const MEASUREMENTS_TYPE_RR_GEN           = "RR_GEN"
export const MEASUREMENTS_TYPE_WEIGHT           = "WEIGHT"
export const MEASUREMENTS_TYPE_BLO_SYST         = "BLO_SYST"
export const MEASUREMENTS_TYPE_BLO_DIAST        = "BLO_DIAST"
export const MEASUREMENTS_TYPE_BLO_ARR          = "BLO_ARR"


export const UNIT_MEASURE_HR            = "bpm"
export const UNIT_MEASURE_BR            = "rpm"
export const UNIT_MEASURE_ST_T          = ""
export const UNIT_MEASURE_SPO2          = "%"

export const SERVER_NOW                 = "SERVER_NOW"
export const SENSE_BATTERY              = "SENSE_BATTERY"

//VOIP
export const VOIP_WS_URL                = "wss://voip.patientmonitoring.eositaly.it:7443"
export const VOIP_URI_PREFIX            = "sip:"
export const VOIP_URI_SUFFIX            = "@voip.patientmonitoring.eositaly.it"
export const VOIP_REALM                 = "voip.patientmonitoring.eositaly.it"
