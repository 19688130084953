/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React from "react";
import { FormattedMessage } from "react-intl";
import { useLocation } from "react-router";
import { NavLink } from "react-router-dom";
import SVG from "react-inlinesvg";
import { toAbsoluteUrl, checkIsActive } from "../../../../_helpers";

export function AsideMenuList({ layoutProps }) {
  const location = useLocation();
  const getMenuItemActive = (url) => {
    return checkIsActive(location, url)
      ? " menu-item-active menu-item-open "
      : "";
  };

  return (
    <>
      {/* begin::Menu Nav */}
      <ul className={`menu-nav ${layoutProps.ulClasses}`}>
        {/*begin::1 Level*/}
        <li
          className={`menu-item ${getMenuItemActive("/dashboard")}`}
          aria-haspopup="true"
        >
          <NavLink className="menu-link" to="/dashboard">
            <span className="svg-icon menu-icon">
              <SVG src={toAbsoluteUrl("/media/svg/icons/Home/Home.svg")} />
            </span>
            <span className="menu-text"><FormattedMessage id="MENU.DASHBOARD" /></span>
          </NavLink>
        </li>
        {/*end::1 Level*/}









        {/* begin::section */}
        <li className="menu-section ">
          <h4 className="menu-text"><FormattedMessage id="MENU.PATIENT_DIVIDER" /></h4>
          <i className="menu-icon flaticon-more-v2"></i>
        </li>
        {/* end:: section */}





        {/*begin::1 Level*/}
        <li
          className={`menu-item ${getMenuItemActive("/patient-new")}`}
          aria-haspopup="true"
        >
          <NavLink className="menu-link" to="/patient-new">
            <span className="svg-icon menu-icon">
              <SVG src={toAbsoluteUrl("/media/svg/icons/Communication/Add-user.svg")} />
            </span>
            <span className="menu-text"><FormattedMessage id="MENU.NEW_PATIENT" /></span>
          </NavLink>
        </li>
        {/*end::1 Level*/}













        {/*begin::1 Level*/}
        <li
          className={`menu-item ${getMenuItemActive("/patient-monitored-list")}`}
          aria-haspopup="true"
        >
          <NavLink className="menu-link" to="/patient-monitored-list">
            <span className="svg-icon menu-icon">
              <SVG src={toAbsoluteUrl("/media/svg/icons/Communication/Adress-book2.svg")} />
            </span>
            <span className="menu-text"><FormattedMessage id="MENU.PATIENT_MONITORED_LIST" /></span>
          </NavLink>
        </li>
        {/*end::1 Level*/}







        {/*begin::1 Level*/}
         <li
          className={`menu-item ${getMenuItemActive("/patient-medical-history-card")}`}
          aria-haspopup="true"
        >
          <NavLink className="menu-link" to="/patient-medical-history-card">
            <span className="svg-icon menu-icon">
              <SVG src={toAbsoluteUrl("/media/svg/icons/Communication/Clipboard-list.svg")} />
            </span>
            <span className="menu-text"><FormattedMessage id="MENU.PATIENT_MEDICAL_CARD" /></span>
          </NavLink>
        </li>
        {/*end::1 Level*/}











        {/*begin::1 Level*/}
        <li
          className={`menu-item ${getMenuItemActive("/patient-covid-history-card")}`}
          aria-haspopup="true"
        >
          <NavLink className="menu-link" to="/patient-covid-history-card">
            <span className="svg-icon menu-icon">
              <SVG src={toAbsoluteUrl("/media/svg/icons/Custom/Corona-virus.svg")} />
            </span>
            <span className="menu-text"><FormattedMessage id="MENU.PATIENT_COVID_CARD" /></span>
          </NavLink>
        </li>
        {/*end::1 Level*/}









        {/*begin::1 Level*/}
        <li
          className={`menu-item ${getMenuItemActive("/patient-live-monitoring")}`}
          aria-haspopup="true"
        >
          <NavLink className="menu-link" to="/patient-live-monitoring">
            <span className="svg-icon menu-icon">
              <SVG src={toAbsoluteUrl("/media/svg/icons/Shopping/Barcode-scan.svg")} />
            </span>
            <span className="menu-text"><FormattedMessage id="MENU.PATIENT_LIVE_MONITORING" /></span>
          </NavLink>
        </li>
        {/*end::1 Level*/}







        {/*begin::1 Level*/}
        <li
          className={`menu-item ${getMenuItemActive("/patient-historic-monitoring")}`}
          aria-haspopup="true"
        >
          <NavLink className="menu-link" to="/patient-historic-monitoring">
            <span className="svg-icon menu-icon">
              <SVG src={toAbsoluteUrl("/media/svg/icons/Code/Time-schedule.svg")} />
            </span>
            <span className="menu-text"><FormattedMessage id="MENU.PATIENT_HISTORIC_MONITORING" /></span>
          </NavLink>
        </li>
        {/*end::1 Level*/}












        {/* begin::section */}
        <li className="menu-section ">
          <h4 className="menu-text"><FormattedMessage id="MENU.DOCTOR_DIVIDER" /></h4>
          <i className="menu-icon flaticon-more-v2"></i>
        </li>
        {/* end:: section */}







        {/*begin::1 Level*/}
        <li
          className={`menu-item ${getMenuItemActive("/doctor-new")}`}
          aria-haspopup="true"
        >
          <NavLink className="menu-link" to="/doctor-new">
            <span className="svg-icon menu-icon">
              <SVG src={toAbsoluteUrl("/media/svg/icons/Communication/Add-user.svg")} />
            </span>
            <span className="menu-text"><FormattedMessage id="MENU.DOCTOR_NEW" /></span>
          </NavLink>
        </li>
        {/*end::1 Level*/}







        {/*begin::1 Level*/}
        <li
          className={`menu-item ${getMenuItemActive("/doctor-list")}`}
          aria-haspopup="true"
        >
          <NavLink className="menu-link" to="/doctor-list">
            <span className="svg-icon menu-icon">
              <SVG src={toAbsoluteUrl("/media/svg/icons/Communication/Adress-book2.svg")} />
            </span>
            <span className="menu-text"><FormattedMessage id="MENU.DOCTOR_LIST" /></span>
          </NavLink>
        </li>
        {/*end::1 Level*/}











        {/* Custom */}
        {/* begin::section */}
        <li className="menu-section ">
          <h4 className="menu-text"><FormattedMessage id="MENU.GENERAL_SETTINGS_DIVIDER" /></h4>
          <i className="menu-icon flaticon-more-v2"></i>
        </li>
        {/* end:: section */}


        {/*begin::1 Level*/}
        <li
          className={`menu-item ${getMenuItemActive("/notifications")}`}
          aria-haspopup="true"
        >
          <NavLink className="menu-link" to="/notifications">
            <span className="svg-icon menu-icon">
              <SVG src={toAbsoluteUrl("/media/svg/icons/Code/Warning-1-circle.svg")} />
            </span>
            <span className="menu-text"><FormattedMessage id="MENU.ALERT_CUSTOMIZATION" /></span>
          </NavLink>
        </li>
        {/*end::1 Level*/}
  
        {/*begin::1 Level*/}
        <li
          className={`menu-item ${getMenuItemActive("/download-apps")}`}
          aria-haspopup="true"
        >
          <NavLink className="menu-link" to="/download-apps">
            <span className="svg-icon menu-icon">
              <SVG src={toAbsoluteUrl("/media/svg/icons/Files/Cloud-Download.svg")} />
            </span>
            <span className="menu-text"><FormattedMessage id="MENU.ALERT_DOWNLOAD_APPS" /></span>
          </NavLink>
        </li>
        {/*end::1 Level*/}



        {/* <li
          className={`menu-item menu-item-submenu ${getMenuItemActive(
            "/error"
          )}`}
          aria-haspopup="true"
          data-menu-toggle="hover"
        >
          <NavLink className="menu-link menu-toggle" to="/error">
            <span className="svg-icon menu-icon">
              <SVG
                src={toAbsoluteUrl("/media/svg/icons/Code/Settings4.svg")}
              />
            </span>
            <span className="menu-text"><FormattedMessage id="MENU.SETTINGS" /></span>
            <i className="menu-arrow" />
          </NavLink>
          <div className="menu-submenu ">
            <i className="menu-arrow" />
            <ul className="menu-subnav">
              <li className="menu-item  menu-item-parent" aria-haspopup="true">
                <span className="menu-link">
                  <span className="menu-text">Configurazioni</span>
                </span>
              </li>

              <li
                className={`menu-item ${getMenuItemActive("/error/error-v1")}`}
                aria-haspopup="true"
              >
                <NavLink className="menu-link" to="/error/error-v1">
                  <i className="menu-bullet menu-bullet-dot">
                    <span />
                  </i>
                  <span className="menu-text"><FormattedMessage id="MENU.STRUCTURAL_REGISTRY" /></span>
                </NavLink>
              </li>

              <li
                className={`menu-item ${getMenuItemActive("/error/error-v2")}`}
                aria-haspopup="true"
              >
                <NavLink className="menu-link" to="/error/error-v2">
                  <i className="menu-bullet menu-bullet-dot">
                    <span />
                  </i>
                  <span className="menu-text"><FormattedMessage id="MENU.USER_MANAGEMENT" /></span>
                </NavLink>
              </li>
              
              <li
                className={`menu-item ${getMenuItemActive("/error/error-v3")}`}
                aria-haspopup="true"
              >
                <NavLink className="menu-link" to="/error/error-v3">
                  <i className="menu-bullet menu-bullet-dot">
                    <span />
                  </i>
                  <span className="menu-text"><FormattedMessage id="MENU.SENSORS" /></span>
                </NavLink>
              </li>

              <li
                className={`menu-item ${getMenuItemActive("/error/error-v3")}`}
                aria-haspopup="true"
              >
                <NavLink className="menu-link" to="/error/error-v3" rel='noopener noreferrer' download>
                  <i className="menu-bullet menu-bullet-dot">
                    <span />
                  </i>
                  <span className="menu-text"><FormattedMessage id="MENU.DOWNLOAD_APP" /></span>
                </NavLink>
              </li>

            </ul>
          </div>
        </li> */}
      </ul>

    </>
  );
}
