import React, { useEffect } from "react";
import { useSubheader } from "../../_metronic/layout";
import { injectIntl } from "react-intl";

import { UserAgent, Registerer, Inviter } from "sip.js";




function Voip() {
  const subHeader = useSubheader();
  subHeader.setTitle("Voip");

  useEffect(() => {
    // doctorAgent.start().then(() => {
    //   doctorRegisterer.register();
    // });

    // eslint-disable-next-line react-hooks/exhaustive-deps
}, []);

  const doctorOptions = {
    acuthorizationUsername: '1014',
    authorizationPassword: '6eSk2HIJ?Q',
    transportOptions: {
      server: 'wss://patientmonitoring.eositaly.it:7443'
    },
    uri: UserAgent.makeURI('sip:1014@patientmonitoring.eositaly.it')
  }
  
  const doctorAgent = new UserAgent(doctorOptions);
  const doctorRegisterer = new Registerer(doctorAgent);

  const call = () => {
  const target = UserAgent.makeURI("sip:1010@patientmonitoring.eositaly.it");

  const inviter = new Inviter(doctorAgent, target);
  inviter.invite();

  doctorAgent.transport.on('connected', () => doctorRegisterer.register())
}

  return (
    <>
      <video id="remoteVideo"></video>
      <video id="localVideo" muted="muted"></video>
      <button className="btn-action btn-success" onClick={call}>Call</button>
    </>
  );
}


export default injectIntl(Voip);