import React, { useEffect, useState } from "react";
import { FormattedMessage, injectIntl } from "react-intl";

import clsx from 'clsx';
import { useSubheader } from "../../_metronic/layout";

import { useFormik } from "formik";
import * as Yup from "yup";

import SaveIcon from '@material-ui/icons/Save';
import PrintIcon from '@material-ui/icons/Print';
import { makeStyles } from '@material-ui/core/styles';

import * as PatientService from '../services/PatientService';
import * as UtilService from '../services/UtilService';

import * as AlertService from '../AlertService';
import { useAlert } from 'react-alert';

import * as Constants from '../Constants';

import * as StringUtils from '../StringUtils';

import QRCode from 'qrcode.react';

import { toAbsoluteUrl } from "../../_metronic/_helpers";
import SVG from "react-inlinesvg";

import Autocomplete from '@material-ui/lab/Autocomplete';
import {
    TextField, MenuItem, FormControl, InputLabel,
    OutlinedInput, Select, Button, RadioGroup, FormControlLabel, Radio
} from "@material-ui/core";

import LoadingSpinner from '../components/loading-spinner/LoadingSpinner';

//import '~leaflet/dist/leaflet.css';
import { MapContainer, TileLayer, Marker, Popup } from 'react-leaflet'

const useStyles = makeStyles(theme => ({
    button: {
        margin: theme.spacing(1),
    },
    leftIcon: {
        marginRight: theme.spacing(1),
    },
    rightIcon: {
        marginLeft: theme.spacing(1),
    },
    iconSmall: {
        fontSize: 20,
    },
}));


function PatientEdit({ intl, ...props }) {

    const [isLoading, setIsLoading] = useState(true);

    const alert = useAlert();

    const patientId = props.match.params.id;

    const [qrCode, setQrCode] = useState({});
    const [lastPosition, setLastPosition] = useState([]);

    useEffect(() => {
        const initialize = async () => {
            const rawProvince = await UtilService.getAllProvince();
            const province = await rawProvince.json();
            setProvinceNascita(province);
            setProvinceAddress(province);

            const rawData = await PatientService.findPatientById(patientId);
            if (rawData.status === Constants.STATUTS_200) {
                const data = await rawData.json();
                fillForm(data);
                setQrCode({
                    'qr': data.qrCode,
                    'env': Constants.ENVIRONMENT
                });
                setLastPosition(data.lastPosition ? data.lastPosition : []);
                setIsLoading(false);
            }
        }

        initialize();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const fillForm = (data) => {
        formik.setFieldValue('lastName', data.lastName);
        formik.setFieldValue('firstName', data.firstName);
        formik.setFieldValue('fiscalCode', data.fiscalCode);
        formik.setFieldValue('gender', data.gender);
        formik.setFieldValue('birthDate', data.birthDate);
        formik.setFieldValue('birthPro', data.birthPro);
        formik.setFieldValue('birthTown', data.birthTown);
        formik.setFieldValue('address', data.address);
        formik.setFieldValue('addressPro', data.addressPro);
        formik.setFieldValue('addressTown', data.addressTown);
        formik.setFieldValue('phone', data.phone);
        formik.setFieldValue('email', data.email);
        formik.setFieldValue('doctor', data.doctor);
        formik.setFieldValue('phoneDoctor', data.phoneDoctor);
        formik.setFieldValue('caregiver', data.caregiver);
        formik.setFieldValue('caregiverPhone', data.caregiverPhone);
        formik.setFieldValue('typeMonitoring', data.typeMonitoring);
        formik.setFieldValue('enabled', data.enabled);
        formik.setFieldValue('voipUser', data.voipUser);
        // formik.setFieldValue('voipPassword', data.voipPassword);

        setSelectedProvNascita({ provinciasigla: data.birthPro });
        setSelectedComNascita({ comunedescrizione: data.birthTown });
        setSelectedProvAddress({ provinciasigla: data.addressPro });
        setSelectedComAddress({ comunedescrizione: data.addressTown });

        getComuniBySiglaProvincia(data.birthPro, true);
        getComuniBySiglaProvincia(data.addressPro, false);
    }

    const suhbeader = useSubheader();
    suhbeader.setTitle(intl.formatMessage({ id: 'PAGES.EDIT_PATIENT' }));
    const classes = useStyles();
    const inputLabel = React.useRef(null);
    const [labelWidth, setLabelWidth] = React.useState(0);
    React.useEffect(() => {
        setLabelWidth(inputLabel.current.offsetWidth);
    }, []);

    const newPatientSchema = Yup.object().shape({
        firstName: Yup.string()
            .required(intl.formatMessage({ id: 'ERRORS.REQUIRED_FIELD' })),
        lastName: Yup.string()
            .required(intl.formatMessage({ id: 'ERRORS.REQUIRED_FIELD' })),
        fiscalCode: Yup.string()
            .required(intl.formatMessage({ id: 'ERRORS.REQUIRED_FIELD' })),
        birthDate: Yup.date()
            .required(intl.formatMessage({ id: 'ERRORS.REQUIRED_FIELD' })),
        gender: Yup.string()
            .required(intl.formatMessage({ id: 'ERRORS.REQUIRED_FIELD' })),
        birthPro: Yup.string()
            .required(intl.formatMessage({ id: 'ERRORS.REQUIRED_FIELD' })),
        birthTown: Yup.string()
            .required(intl.formatMessage({ id: 'ERRORS.REQUIRED_FIELD' })),
        address: Yup.string()
            .required(intl.formatMessage({ id: 'ERRORS.REQUIRED_FIELD' })),
        addressPro: Yup.string()
            .required(intl.formatMessage({ id: 'ERRORS.REQUIRED_FIELD' })),
        addressTown: Yup.string()
            .required(intl.formatMessage({ id: 'ERRORS.REQUIRED_FIELD' })),
        phone: Yup.string()
            .required(intl.formatMessage({ id: 'ERRORS.REQUIRED_FIELD' })),
        email: Yup.string()
            .email(intl.formatMessage({ id: 'ERRORS.WRONG_EMAIL_FORMAT' }))
            .required(intl.formatMessage({ id: 'ERRORS.REQUIRED_FIELD' })),
        doctor: Yup.string()
            .required(intl.formatMessage({ id: 'ERRORS.REQUIRED_FIELD' })),
        typeMonitoring: Yup.string()
            .required(intl.formatMessage({ id: 'ERRORS.REQUIRED_FIELD' })),
        phoneDoctor: Yup.string(),
        caregiver: Yup.string(),
        caregiverPhone: Yup.string(),
        enabled: Yup.string(),
        voipUser: Yup.string()
            .required(intl.formatMessage({ id: 'ERRORS.REQUIRED_FIELD' })),
    });

    const formik = useFormik({
        initialValues: {
            firstName: '',
            lastName: '',
            fiscalCode: '',
            gender: 'Maschio',
            birthPro: '',
            birthTown: '',
            address: '',
            addressPro: '',
            addressTown: '',
            phone: '',
            email: '',
            doctor: '',
            typeMonitoring: '',
            phoneDoctor: '',
            caregiver: '',
            caregiverPhone: '',
            enabled: 'S',
            voipUser: '',
        },
        validationSchema: newPatientSchema,
        onSubmit: values => {
            updatePatient(values);
        },
    });

    const updatePatient = async (newPatient) => {
        const rawData = await PatientService.updatePatient(patientId, newPatient);
        if (rawData.status === Constants.STATUTS_201 || rawData.status === Constants.STATUTS_200) {
            AlertService.success(alert);
        } else if (rawData.status === Constants.STATUTS_404) {
            AlertService.info(alert, "Il paziente non è stato modificato");
        } else {
            AlertService.error(alert);
        }
    }

    const enable = async (value) => {
        formik.setFieldValue('enabled', value);
        const rawData = await PatientService.enablePatient(patientId, { 'enabled': value })
        if (rawData.status === Constants.STATUTS_201 || rawData.status === Constants.STATUTS_200) {
            const msg = value === 'S' ? 'Paziente Abilitato correttamente' : 'Paziente Disabilitato correttamente'
            AlertService.success(alert, msg);
        } else {
            AlertService.error(alert);
        }
    }

    //START COMUNI PROVINCE
    const [selectedProvNascita, setSelectedProvNascita] = useState(null);
    const [selectedComNascita, setSelectedComNascita] = useState(null);
    const [selectedProvAddress, setSelectedProvAddress] = useState(null);
    const [selectedComAddress, setSelectedComAddress] = useState(null);

    const [provinceNascita, setProvinceNascita] = useState([]);
    const [comuniNascita, setComuniNascita] = useState([]);

    const [provinceAddress, setProvinceAddress] = useState([]);
    const [comuniAddress, setComuniAddress] = useState([]);

    const handleChangeProvNascita = (e, value) => {
        if (!StringUtils.isEmpty(value)) {
            formik.setFieldValue('birthPro', value.provinciasigla);
            getComuniBySiglaProvincia(value.provinciasigla, true);
        } else {
            formik.setFieldValue('birthPro', '');
            setComuniNascita([]);
        }
        formik.setFieldValue('birthTown', '');
    }

    const handleChangeComNascita = (e, value) => {
        if (!StringUtils.isEmpty(value)) {
            formik.setFieldValue('birthTown', value.comunedescrizione);
        } else {
            formik.setFieldValue('birthTown', '');
        }
    }

    const handleChangeProvAddress = (e, value) => {
        if (!StringUtils.isEmpty(value)) {
            formik.setFieldValue('addressPro', value.provinciasigla);
            getComuniBySiglaProvincia(value.provinciasigla, false);
        } else {
            formik.setFieldValue('addressPro', '');
            setComuniAddress([]);
        }
        formik.setFieldValue('addressTown', '');
    }

    const handleChangeComAddress = (e, value) => {
        if (!StringUtils.isEmpty(value)) {
            formik.setFieldValue('addressTown', value.comunedescrizione);
        } else {
            formik.setFieldValue('addressTown', '');
        }
    }

    const getComuniBySiglaProvincia = async (siglaProv, isNascita) => {
        const rawComuni = await UtilService.getComuniBySiglaProvincia(siglaProv);
        const comuni = await rawComuni.json();
        if (isNascita) {
            setComuniNascita(comuni);
        } else {
            setComuniAddress(comuni);
        }
    }
    //END COMUNI PROVINCE

    return (
        <div className="card card-custom gutter-b" style={{ background: "#fff", color: "#333" }}>
            {isLoading && <LoadingSpinner />}

            <form onSubmit={formik.handleSubmit}>
                <div className="card-body">
                    <div className="row d-print-none">
                        <div className="col-12 mt-4">
                            <h6 className="text-primary" style={{ margin: "0" }}><FormattedMessage id="GENERAL.STRUCTUR" /></h6>
                            <hr style={{ margin: "0" }} />
                        </div>
                        <div className="col-sm-6 col-md-4">
                            <TextField
                                id="outlined-normal"
                                label={`${intl.formatMessage({ id: 'FIELD.LASTNAME' })} *`}
                                margin="normal"
                                variant="outlined"
                                size="small"
                                name="lastName"
                                onChange={formik.handleChange}
                                {...formik.getFieldProps("lastName")}
                            // value={formik.values.lastName}
                            />
                            {formik.touched.lastName && formik.errors.lastName ?
                                <div className="fv-plugins-message-container">
                                    <div className="fv-help-block">{formik.errors.lastName}</div>
                                </div>
                                : null}
                        </div>
                        <div className="col-sm-6 col-md-4">
                            <TextField
                                id="outlined-normal"
                                label={`${intl.formatMessage({ id: 'FIELD.FIRSTNAME' })} *`}
                                margin="normal"
                                variant="outlined"
                                size="small"
                                name="firstName"
                                onChange={formik.handleChange}
                                {...formik.getFieldProps("firstName")}
                            // value={formik.values.firstName}
                            />
                            {formik.touched.firstName && formik.errors.firstName ?
                                <div className="fv-plugins-message-container">
                                    <div className="fv-help-block">{formik.errors.firstName}</div>
                                </div>
                                : null}
                        </div>
                        <div className="col-sm-6 col-md-4">
                            <TextField
                                id="outlined-normal"
                                label={`${intl.formatMessage({ id: 'FIELD.FISCAL_CODE' })} *`}
                                margin="normal"
                                variant="outlined"
                                size="small"
                                name="fiscalCode"
                                onChange={formik.handleChange}
                                {...formik.getFieldProps("fiscalCode")}
                            // value={formik.values.fiscalCode}
                            />
                            {formik.touched.fiscalCode && formik.errors.fiscalCode ?
                                <div className="fv-plugins-message-container">
                                    <div className="fv-help-block">{formik.errors.fiscalCode}</div>
                                </div>
                                : null}
                        </div>

                        <div className="col-sm-6 col-md-2">
                            <FormControl margin="normal" size="small" variant="outlined" className={classes.formControl}>
                                <InputLabel ref={inputLabel} htmlFor="outlined-gender-simple">
                                    <FormattedMessage id="FIELD.GENDER" /> *
                                </InputLabel>
                                <Select
                                    name="gender"
                                    onChange={formik.handleChange}
                                    {...formik.getFieldProps("gender")}
                                    // value={formik.values.gender}
                                    input={<OutlinedInput labelWidth={labelWidth} name="gender" id="outlined-gender-simple" />}
                                >
                                    <MenuItem value={'Maschio'}><FormattedMessage id="FIELD.TYPE_GENDER_MALE" /></MenuItem>
                                    <MenuItem value={'Femmina'}><FormattedMessage id="FIELD.TYPE_GENDER_FEMALE" /></MenuItem>
                                </Select>
                            </FormControl>
                            {formik.touched.gender && formik.errors.gender ?
                                <div className="fv-plugins-message-container">
                                    <div className="fv-help-block">{formik.errors.gender}</div>
                                </div>
                                : null}
                        </div>

                        <div className="col-sm-6 col-md-2">
                            <TextField
                                id="date"
                                label={`${intl.formatMessage({ id: 'FIELD.BIRTH_DATE' })} *`}
                                type="date"
                                // defaultValue="1980-01-01"
                                variant="outlined"
                                size="small"
                                margin="normal"
                                className={classes.textField}
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                name="birthDate"
                                onChange={formik.handleChange}
                                {...formik.getFieldProps("birthDate")}
                            // value={formik.values.birthDate}
                            />
                            {formik.touched.birthDate && formik.errors.birthDate ?
                                <div className="fv-plugins-message-container">
                                    <div className="fv-help-block">{formik.errors.birthDate}</div>
                                </div>
                                : null}
                        </div>
                        <div className="col-sm-6 col-md-1">
                            {selectedProvNascita != null ?
                                <Autocomplete
                                    id="combo-box-demo"
                                    onChange={handleChangeProvNascita}
                                    options={provinceNascita}
                                    defaultValue={selectedProvNascita}
                                    getOptionLabel={(option) => option.provinciasigla}
                                    renderInput={(params) => <TextField
                                        {...params}
                                        id="outlined-normal"
                                        label={`${intl.formatMessage({ id: 'FIELD.PROV' })} *`}
                                        margin="normal"
                                        variant="outlined"
                                        size="small"
                                        name="birthPro"
                                        {...formik.getFieldProps("birthPro")}
                                    />}
                                />
                                : null}
                            {formik.touched.birthPro && formik.errors.birthPro ?
                                <div className="fv-plugins-message-container">
                                    <div className="fv-help-block">{formik.errors.birthPro}</div>
                                </div>
                                : null}
                        </div>
                        <div className="col-sm-6 col-md-3">
                            {selectedComNascita != null ?
                                <Autocomplete
                                    id="combo-box-demo"
                                    disabled={StringUtils.isEmpty(formik.values.birthPro)}
                                    onChange={handleChangeComNascita}
                                    options={comuniNascita}
                                    defaultValue={selectedComNascita}
                                    getOptionLabel={(option) => option.comunedescrizione}
                                    renderInput={(params) => <TextField
                                        {...params}
                                        id="outlined-normal"
                                        label={`${intl.formatMessage({ id: 'FIELD.BIRTH_COMMON' })} *`}
                                        margin="normal"
                                        variant="outlined"
                                        size="small"
                                        name="birthTown"
                                        {...formik.getFieldProps("birthTown")}
                                    />}
                                />
                                : null}
                            {formik.touched.birthTown && formik.errors.birthTown ?
                                <div className="fv-plugins-message-container">
                                    <div className="fv-help-block">{formik.errors.birthTown}</div>
                                </div>
                                : null}
                        </div>



                    </div>

                    <div className="row d-print-none">
                        <div className="col-12 mt-4">
                            <h6 className="text-primary" style={{ margin: "0" }}><FormattedMessage id="GENERAL.CONTACTS" /></h6>
                            <hr style={{ margin: "0" }} />
                        </div>

                        <div className="col-sm-6 col-md-4">
                            <TextField
                                id="outlined-normal"
                                label={`${intl.formatMessage({ id: 'FIELD.HOME_ADDRESS' })} *`}
                                margin="normal"
                                variant="outlined"
                                size="small"
                                name="address"
                                onChange={formik.handleChange}
                                {...formik.getFieldProps("address")}
                            />
                            {formik.touched.address && formik.errors.address ?
                                <div className="fv-plugins-message-container">
                                    <div className="fv-help-block">{formik.errors.address}</div>
                                </div>
                                : null}
                        </div>
                        <div className="col-sm-6 col-md-1">
                            {selectedProvAddress != null ?
                                <Autocomplete
                                    id="combo-box-demo"
                                    defaultValue={selectedProvAddress}
                                    onChange={handleChangeProvAddress}
                                    options={provinceAddress}
                                    getOptionLabel={(option) => option.provinciasigla}
                                    renderInput={(params) => <TextField
                                        {...params}
                                        id="outlined-normal"
                                        label={`${intl.formatMessage({ id: 'FIELD.PROV' })} *`}
                                        margin="normal"
                                        variant="outlined"
                                        size="small"
                                        name="addressPro"
                                        {...formik.getFieldProps("addressPro")}
                                    />}
                                />
                                : null}
                            {formik.touched.addressPro && formik.errors.addressPro ?
                                <div className="fv-plugins-message-container">
                                    <div className="fv-help-block">{formik.errors.addressPro}</div>
                                </div>
                                : null}
                        </div>
                        <div className="col-sm-6 col-md-3">
                            {selectedComAddress != null ?
                                <Autocomplete
                                    id="combo-box-demo"
                                    defaultValue={selectedComAddress}
                                    disabled={StringUtils.isEmpty(formik.values.addressPro)}
                                    onChange={handleChangeComAddress}
                                    options={comuniAddress}
                                    getOptionLabel={(option) => option.comunedescrizione}
                                    renderInput={(params) => <TextField
                                        {...params}
                                        id="outlined-normal"
                                        label={`${intl.formatMessage({ id: 'FIELD.HOME_COMMON' })} *`}
                                        margin="normal"
                                        variant="outlined"
                                        size="small"
                                        name="addressTown"
                                        {...formik.getFieldProps("addressTown")}
                                    />}
                                />
                                : null}
                            {formik.touched.addressTown && formik.errors.addressTown ?
                                <div className="fv-plugins-message-container">
                                    <div className="fv-help-block">{formik.errors.addressTown}</div>
                                </div>
                                : null}
                        </div>
                        <div className="col-sm-6 col-md-4">
                            <TextField
                                id="outlined-normal"
                                label={`${intl.formatMessage({ id: 'USER.EMAIL' })} *`}
                                margin="normal"
                                variant="outlined"
                                size="small"
                                name="email"
                                onChange={formik.handleChange}
                                {...formik.getFieldProps("email")}
                            />
                            {formik.touched.email && formik.errors.email ?
                                <div className="fv-plugins-message-container">
                                    <div className="fv-help-block">{formik.errors.email}</div>
                                </div>
                                : null}
                        </div>



                        <div className="col-sm-6 col-md-4">
                            <TextField
                                id="outlined-normal"
                                label={`${intl.formatMessage({ id: 'FIELD.PHONE' })} *`}
                                margin="normal"
                                variant="outlined"
                                size="small"
                                name="phone"
                                onChange={formik.handleChange}
                                {...formik.getFieldProps("phone")}
                            // value={formik.values.phone}
                            />
                            {formik.touched.phone && formik.errors.phone ?
                                <div className="fv-plugins-message-container">
                                    <div className="fv-help-block">{formik.errors.phone}</div>
                                </div>
                                : null}
                        </div>

                    </div>
                    <div className="row d-print-none">
                        <div className="col-md-6">
                            <div className="col-12 mt-4">
                                <h6 className="text-primary" style={{ margin: "0" }}><FormattedMessage id="GENERAL.TYPE_MONITORING" /> *</h6>
                                <hr style={{ margin: "0" }} />
                            </div>
                            <div className="col">
                                <FormControl component="fieldset" className={classes.formControl}>
                                    <RadioGroup
                                        name="informazioni"
                                        row
                                        className={classes.group}
                                        onChange={formik.handleChange}
                                        {...formik.getFieldProps("typeMonitoring")}
                                    >
                                        <FormControlLabel value="C19" control={<Radio />} label={intl.formatMessage({ id: 'FIELD.TYPE_MONITORING_C19' })} />
                                        <FormControlLabel value="IRES" control={<Radio />} label={intl.formatMessage({ id: 'FIELD.TYPE_MONITORING_IRES' })} />
                                        <FormControlLabel value="ICAR" control={<Radio />} label={intl.formatMessage({ id: 'FIELD.TYPE_MONITORING_ICAR' })} />
                                    </RadioGroup>
                                </FormControl>
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="col-12 mt-4">
                                <h6 className="text-primary" style={{ margin: "0" }}><FormattedMessage id="FIELD.ENABLE_DISABLE" /></h6>
                                <hr style={{ margin: "0" }} />
                            </div>
                            <div className="col">
                                {formik.values.enabled === 'S' ?
                                    <>
                                        <div className="row">
                                            <button
                                                type="button"
                                                className="btn btn-icon btn-light btn-hover-danger btn-sm mx-3"
                                                onClick={() => { enable('N') }}
                                            >
                                                <span className="svg-icon svg-icon-md svg-icon-danger">
                                                    <SVG
                                                        src={toAbsoluteUrl("/media/svg/icons/Electric/Shutdown.svg")}
                                                    />
                                                </span>
                                            </button>
                                            <p className="text-success">{intl.formatMessage({ id: 'FIELD.NOW_ENABLED' })}</p>
                                        </div>
                                    </>
                                    :
                                    <>
                                        <div className="row">
                                            <button
                                                type="button"
                                                className="btn btn-icon btn-light btn-hover-success btn-sm mx-3"
                                                onClick={() => { enable('S') }}
                                            >
                                                <span className="svg-icon svg-icon-md svg-icon-success">
                                                    <SVG
                                                        src={toAbsoluteUrl("/media/svg/icons/Electric/Shutdown.svg")}
                                                    />
                                                </span>
                                            </button>
                                            <p className="text-danger">{intl.formatMessage({ id: 'FIELD.NOW_DISABLED' })}</p>
                                        </div>
                                    </>
                                }
                            </div>
                        </div>
                    </div>
                    <div className="row d-print-none">
                        <div className="col-12 mt-4">
                            <h6 className="text-primary" style={{ margin: "0" }}><FormattedMessage id="GENERAL.CAREGIVER" /></h6>
                            <hr style={{ margin: "0" }} />
                        </div>

                        <div className="col-sm-6 col-md-3">
                            <TextField
                                id="outlined-normal"
                                label={intl.formatMessage({ id: 'FIELD.CAREGIVER' })}
                                margin="normal"
                                variant="outlined"
                                size="small"
                                name="caregiver"
                                onChange={formik.handleChange}
                                {...formik.getFieldProps("caregiver")}
                            // value={formik.values.caregiver}
                            />
                        </div>
                        <div className="col-sm-6 col-md-3">
                            <TextField
                                id="outlined-normal"
                                label={intl.formatMessage({ id: 'FIELD.CAREGIVER_CONTACT' })}
                                margin="normal"
                                variant="outlined"
                                size="small"
                                name="caregiverPhone"
                                onChange={formik.handleChange}
                                {...formik.getFieldProps("caregiverPhone")}
                            // value={formik.values.caregiverPhone}
                            />
                        </div>
                        <div className="col-sm-6 col-md-3">
                            <TextField
                                id="outlined-normal"
                                label={`${intl.formatMessage({ id: 'FIELD.OWN_DOCTOR' })} *`}
                                margin="normal"
                                variant="outlined"
                                size="small"
                                name="doctor"
                                onChange={formik.handleChange}
                                {...formik.getFieldProps("doctor")}
                            // value={formik.values.doctor}
                            />
                            {formik.touched.doctor && formik.errors.doctor ?
                                <div className="fv-plugins-message-container">
                                    <div className="fv-help-block">{formik.errors.doctor}</div>
                                </div>
                                : null}
                        </div>
                        <div className="col-sm-6 col-md-3">
                            <TextField
                                id="outlined-normal"
                                label={`${intl.formatMessage({ id: 'FIELD.OWN_DOCTOR_CONTACT' })}`}
                                margin="normal"
                                variant="outlined"
                                size="small"
                                name="phoneDoctor"
                                onChange={formik.handleChange}
                                {...formik.getFieldProps("phoneDoctor")}
                            // value={formik.values.phoneDoctor}
                            />
                        </div>
                    </div>
                    <div className="row d-print-none">
                        <div className="col-12 mt-4">
                            <h6 className="text-primary" style={{ margin: "0" }}><FormattedMessage id="GENERAL.VOIP_DATAS" /></h6>
                            <hr style={{ margin: "0" }} />
                        </div>
                        <div className="col-sm-6 col-md-4">
                            <TextField
                                id="outlined-normal"
                                label={`${intl.formatMessage({ id: 'FIELD.VOIP_INTERNAL' })} *`}
                                margin="normal"
                                variant="outlined"
                                size="small"
                                name="voipUser"
                                onChange={formik.handleChange}
                                {...formik.getFieldProps("voipUser")}
                            />
                            {formik.touched.voipUser && formik.errors.voipUser ?
                                <div className="fv-plugins-message-container">
                                    <div className="fv-help-block">{formik.errors.voipUser}</div>
                                </div>
                                : null}
                        </div>
                    </div>
                    <div className="row d-print-none">
                        <div className="col-12 mt-4">
                            <h6 className="text-primary" style={{ margin: "0" }}><FormattedMessage id="GENERAL.QR_CODE" /></h6>
                            <hr style={{ margin: "0" }} />
                        </div>

                        <div className="col-md-4">
                            <QRCode value={JSON.stringify(qrCode)} size={290} level={"H"} includeMargin={true} />
                        </div>
                    </div>
                    <div className="row d-print-none">
                        <div className="col-12 mt-4">
                            <h6 className="text-primary" style={{ margin: "0" }}>LOCALIZZAZIONE</h6>
                            <hr style={{ margin: "0" }} />
                        </div>

                        <div className="col-12 mt-4">
                            {lastPosition.length > 0 ? (
                                <MapContainer center={lastPosition} zoom={13} scrollWheelZoom={false} style={{ width: '100%', height: '450px', zIndex: '0' }}>
                                    <TileLayer
                                        attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
                                        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                                    />
                                    <Marker position={lastPosition}>
                                        <Popup>
                                            Ultima posizione conosciuta.
                                    </Popup>
                                    </Marker>
                                </MapContainer>
                            ) : (
                                    <h1>Nessuna posizione registrata</h1>
                                )}
                        </div>
                    </div>
                    <div className="row d-none d-print-block">
                        <div className="col-12 my-4">
                            <h6 className="text-primary" style={{ margin: "0" }}><FormattedMessage id="PRINT_QR.PATIENT_QR_CODE" /></h6>
                            <hr style={{ margin: "0" }} />
                        </div>

                        <div className="col-12">
                            <p><strong><FormattedMessage id="PRINT_QR.PATIENT_FULLNAME" />:</strong> {formik.values.lastName} {formik.values.firstName}</p>
                            <p><strong><FormattedMessage id="GENERAL.QR_CODE" />:</strong> {qrCode.qr}</p>
                            <QRCode value={JSON.stringify(qrCode)} size={290} level={"H"} includeMargin={true} />
                        </div>
                    </div>
                </div>
                <div className="card-footer py-4 d-print-none">
                    <Button type="submit" variant="contained" color="primary" size="small" className={classes.button}
                        disabled={!formik.isValid || !formik.dirty}
                    >
                        <FormattedMessage id="ACTIONS.SAVE" />
                        <SaveIcon className={clsx(classes.rightIcon, classes.iconSmall)} />
                    </Button>

                    <Button type="button" variant="contained" color="inherit" size="small" className={classes.button}
                        onClick={() => { window.print() }}
                    >
                        <FormattedMessage id="ACTIONS.PRINT" />
                        <PrintIcon className={clsx(classes.rightIcon, classes.iconSmall)} />
                    </Button>

                    <span className="text-muted float-right mt-4">* <FormattedMessage id="GENERAL.REQUIRED_FIELDS" /></span>

                </div>
            </form>
        </div>
    );
}

export default injectIntl(PatientEdit);
