import React, { useState, useEffect } from "react";
import { injectIntl } from "react-intl";
import { format as dateFormat } from 'date-fns';
import { Tab, Dropdown, OverlayTrigger, Tooltip } from "react-bootstrap";
import { Badge } from '@material-ui/core';
import PerfectScrollbar from "react-perfect-scrollbar";
import SVG from "react-inlinesvg";
import { toAbsoluteUrl } from "../../../_metronic/_helpers";
import { DropdownTopbarItemToggler } from "../../../_metronic/_partials/dropdowns/DropdownTopbarItemToggler";

import * as Utils from '../../Utils';
import * as AlertService from '../../services/AlertService';
import * as LiveService from '../../services/LiveService';

const perfectScrollbarOptions = {
  wheelSpeed: 2,
  wheelPropagation: false,
};

let pageNumber = 0;
let pageHasNext = false;

function AlertListDpropdown() {
  const bgImage = toAbsoluteUrl("/media/misc/bg-1.jpg");

  const [countAlerts, setCountAlerts] = useState(0);
  const [listAlerts, setListAlerts] = useState([]);
  const [newAlert, setNewAlert] = useState(false);

  useEffect(() => {
    initialPage();
    const socket = LiveService.liveAlertNotificationChannel(setCountAlerts, setNewAlert);
    return () => {
      socket.close();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const initialPage = () => {
    pageNumber = 0;
    getListAlerts(15);

  }

  // const nextPage = () => {
  //   if (pageHasNext) {
  //     pageNumber = pageNumber + 1;
  //     getListAlerts(15);
  //   }
  // }


  const getListAlerts = async (pageSize) => {
    setNewAlert(false);
    const rawResp = await AlertService.getListAlerts(true, false, pageNumber, pageSize);
    if (rawResp.status === 200) {
      const resp = await rawResp.json();
      pageHasNext = resp.data.page.hasNext;
      setListAlerts(oldListAlerts => oldListAlerts.concat(resp.data.list));
    }
  }

  const checkAlertAsRead = async (alert) => {
    AlertService.checkAlertAsRead(alert.alertID).then(() => {
      let newListAlerts = [];
      let giro = 0;
      listAlerts.forEach((elem) => {
        if (elem.alertID !== alert.alertID) {
          console.log(giro);
          newListAlerts.push(elem);
          giro++;
        }
      });
      setListAlerts(newListAlerts);
      /*if (giro <= 4) {
        console.log(listAlerts);
        setListAlerts(newListAlerts);
        if (pageHasNext) {
          pageNumber = pageNumber + 1;
          getListAlerts(15);
        }
        console.log(listAlerts);

      } else {
        setListAlerts(newListAlerts);
      }*/

    });
  }

  return (
    <>
      <Dropdown drop="down" alignRight>
        <Dropdown.Toggle
          as={DropdownTopbarItemToggler}
          id="kt_quick_notifications_toggle"
        >
          <OverlayTrigger
            placement="bottom"
            overlay={
              <Tooltip id="user-notification-tooltip">
                Espandi Notifications
              </Tooltip>
            }
          >
            <div
              className={`btn btn-icon btn-clean btn-lg mr-1 ${newAlert ? 'pulse pulse-danger' : ''}`}
              id="kt_quick_notifications_toggle"
            >
              <Badge badgeContent={countAlerts} max={999} color={`${newAlert ? 'error' : 'primary'}`}>
                <span className="svg-icon svg-icon-xl">
                  <SVG
                    src={toAbsoluteUrl("/media/svg/icons/Custom/Bell.svg")}
                  />
                </span>
              </Badge>
              {newAlert ? (<>
                <span className="pulse-ring"></span>
                <span className="pulse-ring" />
              </>) : null}
            </div>
          </OverlayTrigger>
        </Dropdown.Toggle>
        <Dropdown.Menu className="dropdown-menu p-0 m-0 dropdown-menu-right dropdown-menu-anim-up dropdown-menu-lg">
          <div
            className="d-flex flex-column bgi-size-cover bgi-no-repeat rounded-top"
            style={{ backgroundImage: `url(${bgImage})`, backgroundPosition: '0', backgroundSize: '350px 200px', backgroundRepeat: 'repeat' }}
          >
            <div className="d-flex justify-content-around align-items-center rounded-top py-8 mb-0">
              <h2 className="text-white mb-0">Notifications in arrivo</h2>
              {countAlerts > 0 ?
                (
                  <span className="badge badge-success font-size-h5">
                    {countAlerts} Nuovi
                  </span>
                )
                : null}
            </div>

            <Tab.Container defaultActiveKey={"Alerts"}>
              <Tab.Content className="tab-content">
                <Tab.Pane eventKey="Alerts">
                  <PerfectScrollbar
                    options={perfectScrollbarOptions}
                    className="navi navi-hover scroll my-4"
                    style={{ maxHeight: "300px", position: "relative" }}
                    //onYReachEnd={nextPage}
                  >
                    {listAlerts.map((elem, index) => (
                      <div key={elem.alertID}>
                        <OverlayTrigger
                          placement="left"
                          overlay={
                            <Tooltip id="user-notification-tooltip">
                              Clicca per marcare come letto
                            </Tooltip>
                          }
                        >
                          <a href={() => null} onClick={() => { checkAlertAsRead(elem); }} className="navi-item">
                            <div className="navi-link">
                              <span className={`svg-icon svg-icon-md svg-icon-${Utils.getClassByAlertUrgencyLevel(elem.urgencyLevel.id)} mr-5`}>
                                <SVG src={toAbsoluteUrl("/media/svg/icons/Design/Circle.svg")} />
                              </span>
                              <div className="navi-text">
                                <div className="font-weight-bold">
                                  {elem.alert}
                                </div>
                                <div className={`text-${Utils.getClassByAlertUrgencyLevel(elem.urgencyLevel.id)}`}>{`${elem.patient.lastName} ${elem.patient.firstName}`}</div>
                                <div className="text-muted">{dateFormat(new Date(elem.timestamp), 'dd/MM/yyyy HH:mm')}</div>
                              </div>
                            </div>
                          </a>
                        </OverlayTrigger>
                      </div>
                    ))}
                  </PerfectScrollbar>
                </Tab.Pane>
              </Tab.Content>
            </Tab.Container>
          </div>
        </Dropdown.Menu>
      </Dropdown>
    </>
  );
}

export default injectIntl(AlertListDpropdown);
