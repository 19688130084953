import React from "react";
import { FormattedMessage, injectIntl } from "react-intl";

import clsx from 'clsx';
import { useSubheader } from "../../_metronic/layout";

import { useFormik } from "formik";
import * as Yup from "yup";

import SaveIcon from '@material-ui/icons/Save';
import { makeStyles } from '@material-ui/core/styles';

import * as DoctorService from '../services/DoctorService';
import * as Constants from '../Constants';

import { TextField, Button } from "@material-ui/core";

const useStyles = makeStyles(theme => ({
    button: {
        margin: theme.spacing(1),
    },
    leftIcon: {
        marginRight: theme.spacing(1),
    },
    rightIcon: {
        marginLeft: theme.spacing(1),
    },
    iconSmall: {
        fontSize: 20,
    },
}));


function DoctorNew({ intl, ...props }) {

    const suhbeader = useSubheader();
    suhbeader.setTitle(intl.formatMessage({ id: 'PAGES.DOCTOR_NEW' }));
    const classes = useStyles();

    const newPatientSchema = Yup.object().shape({
        firstName: Yup.string()
            .required(intl.formatMessage({ id: 'ERRORS.REQUIRED_FIELD' })),
        lastName: Yup.string()
            .required(intl.formatMessage({ id: 'ERRORS.REQUIRED_FIELD' })),
        tipoMedico: Yup.string()
            .required(intl.formatMessage({ id: 'ERRORS.REQUIRED_FIELD' })),
        email: Yup.string()
            .email(intl.formatMessage({ id: 'ERRORS.WRONG_EMAIL_FORMAT' }))
            .required(intl.formatMessage({ id: 'ERRORS.REQUIRED_FIELD' })),
        password: Yup.string()
            .required(intl.formatMessage({ id: 'ERRORS.REQUIRED_FIELD' })),
        voipUser: Yup.string()
            .required(intl.formatMessage({ id: 'ERRORS.REQUIRED_FIELD' })),
        voipPassword: Yup.string()
            .required(intl.formatMessage({ id: 'ERRORS.REQUIRED_FIELD' })),
    });

    const formik = useFormik({
        initialValues: {
            firstName: '',
            lastName: '',
            tipoMedico: '',
            email: '',
            password: '',
            voipUser: '',
            voipPassword: '',
        },
        validationSchema: newPatientSchema,
        onSubmit: values => {
            addDoctor(values);
        },
    });

    const addDoctor = async (newDoctor) => {
        const rawData = await DoctorService.addDoctor(newDoctor);
        if (rawData.status === Constants.STATUTS_201) {
            const data = await rawData.json();
            props.history.push(`/doctor-edit/${data.id}`);
        }
    }


    return (
        <>
            <div className="card card-custom gutter-b" style={{ background: "#fff", color: "#333" }}>


                <form onSubmit={formik.handleSubmit}>
                    <div className="card-body">
                        <div className="row">
                            <div className="col-12 mt-4">
                                <h6 className="text-primary" style={{ margin: "0" }}><FormattedMessage id="GENERAL.STRUCTUR" /></h6>
                                <hr style={{ margin: "0" }} />
                            </div>
                            <div className="col-sm-6 col-md-4">
                                <TextField
                                    id="outlined-normal"
                                    label={`${intl.formatMessage({ id: 'FIELD.LASTNAME' })} *`}
                                    margin="normal"
                                    variant="outlined"
                                    size="small"
                                    name="lastName"
                                    onChange={formik.handleChange}
                                    {...formik.getFieldProps("lastName")}
                                />
                                {formik.touched.lastName && formik.errors.lastName ?
                                    <div className="fv-plugins-message-container">
                                        <div className="fv-help-block">{formik.errors.lastName}</div>
                                    </div>
                                    : null}
                            </div>
                            <div className="col-sm-6 col-md-4">
                                <TextField
                                    id="outlined-normal"
                                    label={`${intl.formatMessage({ id: 'FIELD.FIRSTNAME' })} *`}
                                    margin="normal"
                                    variant="outlined"
                                    size="small"
                                    name="firstName"
                                    onChange={formik.handleChange}
                                    {...formik.getFieldProps("firstName")}
                                />
                                {formik.touched.firstName && formik.errors.firstName ?
                                    <div className="fv-plugins-message-container">
                                        <div className="fv-help-block">{formik.errors.firstName}</div>
                                    </div>
                                    : null}
                            </div>
                            <div className="col-sm-6 col-md-4">
                                <TextField
                                    id="outlined-normal"
                                    label={`${intl.formatMessage({ id: 'FIELD.TYPE' })} *`}
                                    margin="normal"
                                    variant="outlined"
                                    size="small"
                                    name="email"
                                    onChange={formik.handleChange}
                                    {...formik.getFieldProps("tipoMedico")}
                                />
                                {formik.touched.tipoMedico && formik.errors.tipoMedico ?
                                    <div className="fv-plugins-message-container">
                                        <div className="fv-help-block">{formik.errors.tipoMedico}</div>
                                    </div>
                                    : null}
                            </div>
                            <div className="col-sm-6 col-md-4">
                                <TextField
                                    id="outlined-normal"
                                    label={`${intl.formatMessage({ id: 'USER.EMAIL' })} *`}
                                    autoComplete="false"
                                    margin="normal"
                                    variant="outlined"
                                    size="small"
                                    name="email"
                                    onChange={formik.handleChange}
                                    {...formik.getFieldProps("email")}
                                />
                                {formik.touched.email && formik.errors.email ?
                                    <div className="fv-plugins-message-container">
                                        <div className="fv-help-block">{formik.errors.email}</div>
                                    </div>
                                    : null}
                            </div>
                            <div className="col-sm-6 col-md-4">
                                <TextField
                                    id="outlined-normal"
                                    label={`${intl.formatMessage({ id: 'USER.PASSWORD' })} *`}
                                    type="password"
                                    autoComplete="new-password"
                                    margin="normal"
                                    variant="outlined"
                                    size="small"
                                    name="password"
                                    onChange={formik.handleChange}
                                    {...formik.getFieldProps("password")}
                                />
                                {formik.touched.password && formik.errors.password ?
                                    <div className="fv-plugins-message-container">
                                        <div className="fv-help-block">{formik.errors.password}</div>
                                    </div>
                                    : null}
                            </div>

                        </div>

                        <div className="row">
                            <div className="col-12 mt-4">
                                <h6 className="text-primary" style={{ margin: "0" }}><FormattedMessage id="GENERAL.VOIP_DATAS" /></h6>
                                <hr style={{ margin: "0" }} />
                            </div>
                            <div className="col-sm-6 col-md-4">
                                <TextField
                                    id="outlined-normal"
                                    label={`${intl.formatMessage({ id: 'FIELD.VOIP_INTERNAL' })} *`}
                                    margin="normal"
                                    variant="outlined"
                                    size="small"
                                    name="voipUser"
                                    onChange={formik.handleChange}
                                    {...formik.getFieldProps("voipUser")}
                                />
                                {formik.touched.voipUser && formik.errors.voipUser ?
                                    <div className="fv-plugins-message-container">
                                        <div className="fv-help-block">{formik.errors.voipUser}</div>
                                    </div>
                                    : null}
                            </div>
                            <div className="col-sm-6 col-md-4">
                                <TextField
                                    id="outlined-normal"
                                    label={`${intl.formatMessage({ id: 'FIELD.VOIP_PASSWORD' })} *`}
                                    margin="normal"
                                    variant="outlined"
                                    size="small"
                                    name="voipPassword"
                                    onChange={formik.handleChange}
                                    {...formik.getFieldProps("voipPassword")}
                                />
                                {formik.touched.voipPassword && formik.errors.voipPassword ?
                                    <div className="fv-plugins-message-container">
                                        <div className="fv-help-block">{formik.errors.voipPassword}</div>
                                    </div>
                                    : null}
                            </div>
                        </div>

                    </div>
                    <div className="card-footer py-4">

                        <Button type="submit" variant="contained" color="primary" size="small" className={classes.button}
                            disabled={!formik.isValid || !formik.dirty}
                        >
                            <FormattedMessage id="ACTIONS.SAVE" />
                            <SaveIcon className={clsx(classes.rightIcon, classes.iconSmall)} />
                        </Button>
                        <span className="text-muted float-right mt-4">* <FormattedMessage id="GENERAL.REQUIRED_FIELDS" /></span>

                    </div>
                </form>
            </div>
        </>
    );
};

export default injectIntl(DoctorNew);
