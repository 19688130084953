import React from 'react';
import { useLocation } from "react-router-dom";
import { FormattedMessage } from "react-intl";
import SVG from "react-inlinesvg";
import { toAbsoluteUrl } from "../_metronic/_helpers";
import * as Constants from './Constants';

export function isBlank(string) {
    return string === null || string === undefined || string === '';
}

export function isEmpty(obj) {
    return obj === null || obj === undefined || obj === {};
}

export function formatTypeMonitoring(typeMonitoring) {
    switch (typeMonitoring) {
        case Constants.TYPE_MONITORING_C19:
            return <FormattedMessage id="FIELD.TYPE_MONITORING_C19" />
        case Constants.TYPE_MONITORING_IRES:
            return <FormattedMessage id="FIELD.TYPE_MONITORING_IRES" />
        case Constants.TYPE_MONITORING_ICAR:
            return <FormattedMessage id="FIELD.TYPE_MONITORING_ICAR" />
        default:
            return '';
    }
}

export function formatTypeCovidExam(typeCovidExam) {
    switch (typeCovidExam) {
        case Constants.TYPE_COVID_EXAM_TSR:
            return <FormattedMessage id="COVID_CARD.TYPE_EXAM_TSR" />
        case Constants.TYPE_COVID_EXAM_IGC:
            return <FormattedMessage id="COVID_CARD.TYPE_EXAM_IGC" />
        case Constants.TYPE_COVID_EXAM_IGM:
            return <FormattedMessage id="COVID_CARD.TYPE_EXAM_IGM" />
        case Constants.TYPE_COVID_EXAM_TMP:
            return <FormattedMessage id="COVID_CARD.TYPE_EXAM_TMP" />
        default:
            return '';
    }
}

export function formatOutcomeCovidExam(outcomeCovidExam) {
    switch (outcomeCovidExam) {
        case Constants.TYPE_COVID_EXAM_NEGATIVE:
            return <FormattedMessage id="COVID_CARD.TYPE_EXAM_OUTCOME_NEGATIVE" />
        case Constants.TYPE_COVID_EXAM_POSITIVE:
            return <FormattedMessage id="COVID_CARD.TYPE_EXAM_OUTCOME_POSITIVE" />
        default:
            return '';
    }
}

export function AuthIfNotYet(token) {
    if (isBlank(sessionStorage.getItem('loggedUserToken'))) {
        if (!isBlank(token)) {
            sessionStorage.setItem('loggedUserToken', token);
        }
    }
}

export const getActivityIcon = (activity) => {
    switch (activity) {
        case 'MOVING':
            return (<SVG src={toAbsoluteUrl("/media/svg/icons/Custom/Live/Activity-Moving.svg")} />);
        case 'LYING':
            return (<SVG src={toAbsoluteUrl("/media/svg/icons/Custom/Live/Activity-Lying.svg")} />);
        case 'STILL':
            return (<SVG src={toAbsoluteUrl("/media/svg/icons/Custom/Live/Activity-Still.svg")} />);
        case 'WALKING':
            return (<SVG src={toAbsoluteUrl("/media/svg/icons/Custom/Live/Activity-Walking.svg")} />);
        case 'RUNNING':
            return (<SVG src={toAbsoluteUrl("/media/svg/icons/Custom/Live/Activity-Running.svg")} />);
        default:
            return '';
    }
}

export function useQuery() {
    return new URLSearchParams(useLocation().search);
}


//CSS
export const getClassByAlertUrgencyLevel = (urgencyLevel) => {
    switch (urgencyLevel) {
        case 1:
            return 'danger';
        case 2:
            return 'warning';
        case 3:
            return 'success';
        default:
            return '';
    }
}

export const getQiClassByLevel = (qi) => {
    if (qi > 80) {
        return 'lmSuccessIQFill';
    } else if (qi > 50) {
        return 'lmWarningIQFill';
    } else {
        return 'lmDangerIQFill';
    }
}

export const getQiImageByLevel = (qi) => {
    if (qi > 80) {
        return '/media/misc/lmBar-green.png';
    } else if (qi > 50) {
        return '/media/misc/lmBar-yellow.png';
    } else {
        return '/media/misc/lmBar-red.png';
    }
}
