import React, { useState, useEffect } from 'react';
import { NavLink } from "react-router-dom";
import { FormattedMessage, injectIntl } from "react-intl";
import * as C from '../Constants';

import * as LiveService from '../services/LiveService';

import SVG from "react-inlinesvg";
import { toAbsoluteUrl } from "../../_metronic/_helpers";

import {
    Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Toolbar, Typography
} from "@material-ui/core";
import { makeStyles } from '@material-ui/core/styles';

import { VictoryPie, VictoryLabel } from 'victory';

// import axios from 'axios';

const useStyles = makeStyles(theme => ({
    button: {
        margin: theme.spacing(1),
    },
    leftIcon: {
        marginRight: theme.spacing(1),
    },
    rightIcon: {
        marginLeft: theme.spacing(1),
    },
    iconSmall: {
        fontSize: 20,
    },
}));

function DoctorDashboard({ intl }) {

    const classes = useStyles();

    const [refHighFever, setRefHighFever] = useState(38);
    const [refLowOxygenSaturation, setRefLowOxygenSaturation] = useState(94);
    const [refLastMeasureOld, setRefLastMeasureOld] = useState(24);

    const [totEnabledPatients, setTotEnabledPatients] = useState(0);
    const [countPatientsOnAir, setCountPatientsOnAir] = useState(0);
    const [countPatientsLastMeasureOld, setCountPatientsLastMeasureOld] = useState(0);
    const [countPatientsHighFever, setCountPatientsHighFever] = useState(0);
    const [countPatientsLowOxygenSaturation, setCountPatientsLowOxygenSaturation] = useState(0);
    const [donutChart, setDonutChart] = useState([]);

    useEffect(() => {
        const onChangeData = (type, payload) => {
            switch (type) {
                case C.DASHBOARD_PATIENT_ENABLED:
                    setTotEnabledPatients(payload);
                    break;
                case C.DASHBOARD_PATIENT_ON_AIR:
                    setCountPatientsOnAir(payload);
                    break;
                case C.DASHBOARD_PATIENT_LAST_MEASURE_OLD:
                    setCountPatientsLastMeasureOld(payload);
                    break;
                case C.DASHBOARD_PATIENT_HIGH_TEMP:
                    setCountPatientsHighFever(payload);
                    break;
                case C.DASHBOARD_PATIENT_LOW_SATURATION:
                    setCountPatientsLowOxygenSaturation(payload);
                    break;
                case C.DASHBOARD_PATIENT_MONITORING_TYPES:
                    const donutTemp = [];
                    donutTemp.push({ x: C.TYPE_MONITORING_C19, y: payload.C19 });
                    donutTemp.push({ x: C.TYPE_MONITORING_IRES, y: payload.IRES });
                    donutTemp.push({ x: C.TYPE_MONITORING_ICAR, y: payload.ICAR });
                    setDonutChart(donutTemp);
                    break;
                default:
                    break;
            }
        }

        const onChangeAlertParameters = (payload) => {
            setRefHighFever(payload.alertOnSummary.ST.gte[1]);
            setRefLowOxygenSaturation(payload.alertOnSummary.SpO2.lte[1]);
            setRefLastMeasureOld(payload.disconnectionHours);
        }

        const socket = LiveService.liveDashboardChannel(onChangeData, onChangeAlertParameters);
        return () => { socket.close(); }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <>
            <div className="row">

                {/* SEMPRE BIANCO */}
                <div className="col-md-2-5">
                    <div className="card card-custom gutter-b" style={{ height: "180px" }}>
                        <div className="card-body">
                            <span className="svg-icon svg-icon-3x svg-icon-primary ml-n2">
                                <SVG src={toAbsoluteUrl("/media/svg/icons/Communication/Group.svg")}></SVG>
                            </span>
                            <div className="text-dark font-weight-bolder font-size-h2 mt-3">{totEnabledPatients}</div>
                            <NavLink className="text-muted text-hover-primary font-weight-bold font-size-lg mt-1" to="patient-live-monitoring?enabled=true" ><FormattedMessage id="DASHBOARD.TOTAL_PATIENTS" /></NavLink>
                        </div>
                    </div>
                </div>

                {/* 0 BIANCO E SE > 1 PRIMARY */}
                <div className="col-md-2-5">
                    <div className={`card card-custom gutter-b ${countPatientsOnAir > 0 ? 'bg-primary' : ''}`} style={{ height: "180px" }}>
                        <div className="card-body">
                            <span className={`svg-icon svg-icon-3x ml-n2 ${countPatientsOnAir > 0 ? 'svg-icon-white' : 'svg-icon-primary'}`}>
                                <SVG src={toAbsoluteUrl("/media/svg/icons/Devices/Diagnostics.svg")}></SVG>

                            </span>
                            <div className={`font-weight-bolder font-size-h2 mt-3 ${countPatientsOnAir > 0 ? 'text-inverse-primary' : 'text-dark'}`}>{countPatientsOnAir}</div>
                            <NavLink className={`font-weight-bold font-size-lg mt-1 ${countPatientsOnAir > 0 ? 'text-inverse-primary' : 'text-muted text-hover-primary'}`} to="patient-live-monitoring?online=true" ><FormattedMessage id="DASHBOARD.PATIENTS_NOW_LIVE" /></NavLink>
                        </div>
                    </div>
                </div>

                {/* 0 BIANCO SE <= 5 WARNING SE >5 DANGER */}
                <div className="col-md-2-5">
                    <div className={`card card-custom gutter-b ${countPatientsLastMeasureOld > 0 ? 'bg-warning' : ''}`} style={{ height: "180px" }}>
                        <div className="card-body">
                            <span className={`svg-icon svg-icon-3x ml-n2 ${countPatientsLastMeasureOld > 0 ? 'svg-icon-white' : 'svg-icon-primary'}`}>
                                <SVG src={toAbsoluteUrl("/media/svg/icons/Home/Timer.svg")}></SVG>
                            </span>
                            <div className={`font-weight-bolder font-size-h2 mt-3 ${countPatientsLastMeasureOld > 0 ? 'text-inverse-primary' : 'text-dark'}`}>{countPatientsLastMeasureOld}</div>
                            <NavLink className={`font-weight-bold font-size-lg mt-1 ${countPatientsLastMeasureOld > 0 ? 'text-inverse-primary' : 'text-muted text-hover-primary'}`} to="patient-live-monitoring?lastMeasureAlert=true" >
                                <FormattedMessage id="DASHBOARD.PATIENTS_LAST_MEASURE_OLD" />{` (${refLastMeasureOld}h)`}
                            </NavLink>
                        </div>
                    </div>
                </div>

                {/* 0 BIANCO SE <= 5 WARNING SE >5 DANGER */}
                <div className="col-md-2-5">
                    <div className={`card card-custom gutter-b ${countPatientsHighFever > 0 ? 'bg-danger' : ''}`} style={{ height: "180px" }}>
                        <div className="card-body">
                            <span className={`svg-icon svg-icon-3x ml-n2 ${countPatientsHighFever > 0 ? 'svg-icon-white' : 'svg-icon-primary'}`}>
                                <SVG src={toAbsoluteUrl("/media/svg/icons/Weather/Temperature-full.svg")}></SVG>
                            </span>
                            <div className={`font-weight-bolder font-size-h2 mt-3 ${countPatientsHighFever > 0 ? 'text-inverse-primary' : 'text-dark'}`}>{countPatientsHighFever}</div>

                            <NavLink className={`font-weight-bold font-size-lg mt-1 ${countPatientsHighFever > 0 ? 'text-inverse-primary' : 'text-muted text-hover-primary'}`} to="patient-live-monitoring?lastTemperatureAlert=true" >
                                <FormattedMessage id="DASHBOARD.PATIENTS_HIGH_FEVER" />{` (>${refHighFever}°)`}
                            </NavLink>
                        </div>
                    </div>
                </div>

                {/* 0 BIANCO SE <= 5 WARNING SE >5 DANGER */}
                <div className="col-md-2-5">
                    <div className={`card card-custom gutter-b ${countPatientsLowOxygenSaturation > 0 ? 'bg-danger' : ''}`} style={{ height: "180px" }}>
                        <div className="card-body">
                            <span className={`svg-icon svg-icon-3x ml-n2 ${countPatientsLowOxygenSaturation > 0 ? 'svg-icon-white' : 'svg-icon-primary'}`}>
                                <SVG src={toAbsoluteUrl("/media/svg/icons/Design/Saturation.svg")}></SVG>
                            </span>
                            <div className={`font-weight-bolder font-size-h2 mt-3 ${countPatientsLowOxygenSaturation > 0 ? 'text-inverse-primary' : 'text-dark'}`}>{countPatientsLowOxygenSaturation}</div>
                            
                            <NavLink className={`font-weight-bold font-size-lg mt-1 ${countPatientsLowOxygenSaturation > 0 ? 'text-inverse-primary' : 'text-muted text-hover-primary'}`} to="patient-live-monitoring?lastSaturationAlert=true" >
                                <FormattedMessage id="DASHBOARD.PATIENTS_LOW_OXYGENATION" />{` (<${refLowOxygenSaturation}%)`}
                            </NavLink>
                        </div>
                    </div>
                </div>


            </div>

            <div className="row">
                <div className="col-md-40">
                    <div className="bg-white card pb-4">
                        <Toolbar>
                            <Typography className={classes.title} variant="h6" id="tableTitle" component="div">
                                <div className="mt-6">
                                    <FormattedMessage id="DASHBOARD.GRAPH_TITLE" />
                                </div>
                            </Typography>
                        </Toolbar>
                        <svg viewBox="0 0 400 400">
                            <VictoryPie
                                standalone={false}
                                colorScale={["#6f42c1", "#1BC5BD", "#007bff"]}
                                innerRadius={80}
                                labels={({ datum }) => datum.y !== 0 ? datum.y : ''}
                                data={donutChart}
                                padAngle={({ datum }) => datum.x}
                            />
                            <VictoryLabel
                                textAnchor="middle"
                                style={{
                                    fontSize: "40px"
                                }}
                                x={200} y={200}
                                text={totEnabledPatients}
                            />
                        </svg>
                        <ul style={{ listStyleType: "none" }}>
                            <li style={{ marginBottom: "6px" }}><div className="li-type-monitoring-pie" style={{ backgroundColor: "#6f42c1" }} /> <FormattedMessage id="FIELD.TYPE_MONITORING_C19" /></li>
                            <li style={{ marginBottom: "6px" }}><div className="li-type-monitoring-pie" style={{ backgroundColor: "#1BC5BD" }} /> <FormattedMessage id="FIELD.TYPE_MONITORING_IRES" /></li>
                            <li><div className="li-type-monitoring-pie" style={{ backgroundColor: "#007bff" }} /> <FormattedMessage id="FIELD.TYPE_MONITORING_ICAR" /></li>
                        </ul>
                    </div>
                </div>
                <div className="col-md-60">
                    <div className="bg-white px-6 py-8 card">
                        <Toolbar>
                            <Typography className={classes.title} variant="h6" id="tableTitle" component="div">
                                <div className="row">
                                    <FormattedMessage id="DASHBOARD.TABLE_TITLE" />
                                </div>
                                <div className="row">
                                    <span className="text-muted"><FormattedMessage id="DASHBOARD.TABLE_SUBTITLE" /></span>
                                </div>
                            </Typography>
                        </Toolbar>
                        <TableContainer>
                            <Table className={classes.table} aria-label="customized table">
                                <TableHead>
                                    <TableRow>
                                        <TableCell align="left"><FormattedMessage id="DASHBOARD.TABLE_COLUMN_PATIENT_FULLNAME" /></TableCell>
                                        <TableCell align="left"><FormattedMessage id="DASHBOARD.TABLE_COLUMN_PATIENT_WARNING_TYPE" /></TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </div>
                </div>
            </div>

            <div className="row">
                {/* Escamotage per i cookie. */}
                <img alt="" src={C.BACKEND_URL + '/api/login/cookie?token=' + sessionStorage.getItem('loggedUserToken')}></img>
                <img alt="" src={C.BACKEND_URL + '/api/login/status'}></img>
            </div>
        </>
    );
}

export default injectIntl(DoctorDashboard);