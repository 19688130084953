import { connect } from "socket.io-client";
import * as C from '../Constants'

export const liveMeasurementsChannel = (patientId, onChangeData, onChangeServerTime, onSenseTransmissionOff) => {
    const authToken = sessionStorage.getItem('loggedUserToken');
    const client = connect(`${C.WS_URL}/liveMeasurements`, {
        reconnection: true,
        reconnectionAttempts: Infinity,
        path: "/ws",
        transports: ['websocket'],
        query: {
            authToken: authToken,
            patientId: patientId
        }
    });

    // client.on('subscribe', (data) => {
    //     onChangeData(data);
    // });

    client.on(C.MEASUREMENTS_TYPE_HR, (data) => {
        onChangeData(C.MEASUREMENTS_TYPE_HR, data);
    });

    client.on(C.MEASUREMENTS_TYPE_RR, (data) => {
        onChangeData(C.MEASUREMENTS_TYPE_RR, data);
    });

    client.on(C.MEASUREMENTS_TYPE_ECG, (data) => {
        onChangeData(C.MEASUREMENTS_TYPE_ECG, data);
    });

    client.on(C.MEASUREMENTS_TYPE_BR, (data) => {
        onChangeData(C.MEASUREMENTS_TYPE_BR, data);
    });

    client.on(C.MEASUREMENTS_TYPE_IMP, (data) => {
        onChangeData(C.MEASUREMENTS_TYPE_IMP, data);
    });

    client.on(C.MEASUREMENTS_TYPE_ACT, (data) => {
        onChangeData(C.MEASUREMENTS_TYPE_ACT, data);
    });

    client.on(C.MEASUREMENTS_TYPE_SPEED, (data) => {
        onChangeData(C.MEASUREMENTS_TYPE_SPEED, data);
    });

    client.on(C.MEASUREMENTS_TYPE_ST_F, (data) => {
        onChangeData(C.MEASUREMENTS_TYPE_ST_F, data);
    });

    client.on(C.MEASUREMENTS_TYPE_ST_T, (data) => {
        onChangeData(C.MEASUREMENTS_TYPE_ST_T, data);
    });

    client.on(C.MEASUREMENTS_TYPE_SPO2, (data) => {
        onChangeData(C.MEASUREMENTS_TYPE_SPO2, data);
    });

    client.on(C.MEASUREMENTS_TYPE_HR_QI, (data) => {
        onChangeData(C.MEASUREMENTS_TYPE_HR_QI, data);
    });

    client.on(C.MEASUREMENTS_TYPE_BR_QI, (data) => {
        onChangeData(C.MEASUREMENTS_TYPE_BR_QI, data);
    });

    client.on(C.MEASUREMENTS_TYPE_ACT_QI, (data) => {
        onChangeData(C.MEASUREMENTS_TYPE_ACT_QI, data);
    });

    client.on(C.MEASUREMENTS_TYPE_HR_SAT, (data) => {
        onChangeData(C.MEASUREMENTS_TYPE_HR_SAT, data);
    });

    client.on(C.MEASUREMENTS_TYPE_ARTERY_CHECK, (data) => {
        onChangeData(C.MEASUREMENTS_TYPE_ARTERY_CHECK, data);
    });

    client.on(C.MEASUREMENTS_TYPE_HR_GEN, (data) => {
        onChangeData(C.MEASUREMENTS_TYPE_HR_GEN, data);
    });
    client.on(C.MEASUREMENTS_TYPE_HR_MAD, (data) => {
        onChangeData(C.MEASUREMENTS_TYPE_HR_MAD, data);
    });
    client.on(C.MEASUREMENTS_TYPE_BR_GEN, (data) => {
        onChangeData(C.MEASUREMENTS_TYPE_BR_GEN, data);
    });
    client.on(C.MEASUREMENTS_TYPE_RR_GEN, (data) => {
        onChangeData(C.MEASUREMENTS_TYPE_RR_GEN, data);
    });
    client.on(C.MEASUREMENTS_TYPE_WEIGHT, (data) => {
        onChangeData(C.MEASUREMENTS_TYPE_WEIGHT, data);
    });
    client.on(C.MEASUREMENTS_TYPE_BLO_SYST, (data) => {
        onChangeData(C.MEASUREMENTS_TYPE_BLO_SYST, data);
    });
    client.on(C.MEASUREMENTS_TYPE_BLO_DIAST, (data) => {
        onChangeData(C.MEASUREMENTS_TYPE_BLO_DIAST, data);
    });
    client.on(C.MEASUREMENTS_TYPE_BLO_ARR, (data) => {
        onChangeData(C.MEASUREMENTS_TYPE_BLO_ARR, data);
    });

    client.on('serverTime', (data) => {
        onChangeServerTime(data);
    });

    client.on('senseTransmissionOff', () => {
        onSenseTransmissionOff();
    });

    return client;
};

export const liveDashboardChannel = (onChangeData, onChangeAlertParameters) => {
    const authToken = sessionStorage.getItem('loggedUserToken');
    const client = connect(`${C.WS_URL}/dashboard`, {
        reconnection: true,
        reconnectionAttempts: Infinity,
        path: "/ws",
        transports: ['websocket'],
        query: {
            authToken: authToken,
        }
    });

    client.on(C.DASHBOARD_PATIENT_ENABLED, (data) => {
        onChangeData(C.DASHBOARD_PATIENT_ENABLED, data);
    });

    client.on(C.DASHBOARD_PATIENT_ON_AIR, (data) => {
        onChangeData(C.DASHBOARD_PATIENT_ON_AIR, data);
    });

    client.on(C.DASHBOARD_PATIENT_LAST_MEASURE_OLD, (data) => {
        onChangeData(C.DASHBOARD_PATIENT_LAST_MEASURE_OLD, data);
    });

    client.on(C.DASHBOARD_PATIENT_HIGH_TEMP, (data) => {
        onChangeData(C.DASHBOARD_PATIENT_HIGH_TEMP, data);
    });

    client.on(C.DASHBOARD_PATIENT_LOW_SATURATION, (data) => {
        onChangeData(C.DASHBOARD_PATIENT_LOW_SATURATION, data);
    });

    client.on(C.DASHBOARD_PATIENT_MONITORING_TYPES, (data) => {
        onChangeData(C.DASHBOARD_PATIENT_MONITORING_TYPES, data);
    });

    client.on(C.DASHBOARD_PATIENT_ALERT_PARAMETERS, (alertParameters) => {
        onChangeAlertParameters(alertParameters);
    });

    return client;
};

// export const liveAlertNotificationChannel = (onGetCountAlerts, onIncreaseAlerts, onDecreaseAlerts) => {
export const liveAlertNotificationChannel = (setCountAlerts, setNewAlert) => {
    const authToken = sessionStorage.getItem('loggedUserToken');
    const client = connect(`${C.WS_URL}/alert`, {
        reconnection: true,
        reconnectionAttempts: Infinity,
        path: "/ws",
        transports: ['websocket'],
        query: {
            authToken: authToken,
        }
    });

    client.on('count', (data) => {
        setCountAlerts(data);
    });
    
    client.on('increase', () => {
        setNewAlert(true);
        setCountAlerts(prevCountAlerts => (prevCountAlerts+1));
    });
    
    client.on('decrease', () => {
        setCountAlerts(prevCountAlerts => (prevCountAlerts-1));
    });


    return client;
};