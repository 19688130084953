import React from "react";
import { FormattedMessage, injectIntl } from "react-intl";
import { useFormik } from "formik";
import * as Yup from "yup";

import * as AlertService from '../../../AlertService';
import { useAlert } from 'react-alert';

import * as LoginService from "../../../services/LoginService";
import * as Constants from "../../../Constants"

function Login({ intl }) {
  const alert = useAlert();

  // const [loading, setLoading] = useState(false);

  const LoginSchema = Yup.object().shape({
    email: Yup.string()
      .email(intl.formatMessage({ id: 'ERRORS.WRONG_EMAIL_FORMAT' }))
      .required(intl.formatMessage({ id: 'ERRORS.REQUIRED_FIELD' })),
    password: Yup.string()
      .required(intl.formatMessage({ id: 'ERRORS.REQUIRED_FIELD' }))
  });

  // const enableLoading = () => {
  //   setLoading(true);
  // };

  // const disableLoading = () => {
  //   setLoading(false);
  // };

  const getInputClasses = (fieldname) => {
    if (formik.touched[fieldname] && formik.errors[fieldname]) {
      return "is-invalid";
    }

    if (formik.touched[fieldname] && !formik.errors[fieldname]) {
      return "is-valid";
    }

    return "";
  };

  const formik = useFormik({
    initialValues: {
      email: '',
      password: '',
    },
    validationSchema: LoginSchema,
    onSubmit: values => {
      handleSubmit(values);
    },
  });

  const handleSubmit = async (values) => {
    const rawResp = await LoginService.login(values);
    if (rawResp.status === Constants.STATUTS_201) {
      const resp = await rawResp.json();
      sessionStorage.setItem('loggedUserToken', resp.authToken);
      sessionStorage.setItem('loggedUserEmail', resp.email);
      sessionStorage.setItem('loggedUserRole', resp.role);
      sessionStorage.setItem('loggedUserName', resp.firstName + " " + resp.lastName);
      sessionStorage.setItem('loggedUserSigla', resp.firstName[0] + " " + resp.lastName[0]);
      sessionStorage.setItem('loggedUserVoipUsername', resp.voipUser);
      sessionStorage.setItem('loggedUserVoipPassword', resp.voipPassword);
      window.location.reload();
    } else {
      AlertService.error(alert, intl.formatMessage({ id: 'ERRORS.WRONG_CREDENTIALS' }));
    }
  }


  return (
    <div className="login-form login-signin col-md-6" id="kt_login_signin_form">
      <div className="text-center mb-10 mb-lg-20">
        <h3 className="font-size-h1">
          <FormattedMessage id="LOGIN.USER_IDENTIFICATION" />
        </h3>
        <p className="text-muted font-weight-bold">
          <FormattedMessage id="LOGIN.USER_ENTER_CREDENTIALS" />
        </p>
      </div>

      <form onSubmit={formik.handleSubmit}
        className="form fv-plugins-bootstrap fv-plugins-framework"
      >
        {formik.status ? (
          <div className="mb-10 alert alert-custom alert-light-danger alert-dismissible">
            <div className="alert-text font-weight-bold">{formik.status}</div>
          </div>
        ) : (null
            // <div className="mb-10 alert alert-custom alert-light-info alert-dismissible">
            //   <div className="alert-text ">
            //     Use account <strong>admin@demo.com</strong> and password{" "}
            //     <strong>demo</strong> to continue.
            //   </div>
            // </div>
          )}

        <div className="form-group fv-plugins-icon-container">
          <input
            placeholder={intl.formatMessage({ id: 'USER.EMAIL' })}
            type="email"
            className={`form-control form-control-solid h-auto py-5 px-6 ${getInputClasses(
              "email"
            )}`}
            name="email"
            onChange={formik.handleChange}
            {...formik.getFieldProps("email")}
          />
          {formik.touched.email && formik.errors.email ? (
            <div className="fv-plugins-message-container">
              <div className="fv-help-block">{formik.errors.email}</div>
            </div>
          ) : null}
        </div>
        <div className="form-group fv-plugins-icon-container">
          <input
            placeholder={intl.formatMessage({ id: 'USER.PASSWORD' })}
            type="password"
            className={`form-control form-control-solid h-auto py-5 px-6 ${getInputClasses(
              "password"
            )}`}
            name="password"
            onChange={formik.handleChange}
            {...formik.getFieldProps("password")}
          />
          {formik.touched.password && formik.errors.password ? (
            <div className="fv-plugins-message-container">
              <div className="fv-help-block">{formik.errors.password}</div>
            </div>
          ) : null}
        </div>
        <div>
          <button
            style={{width: '100%'}}
            id="kt_login_signin_submit"
            type="submit"
            disabled={!formik.isValid || !formik.dirty}
            className={`btn btn-primary font-weight-bold px-9 py-4 my-3`}
          >
            <span><FormattedMessage id="USER.LOGIN" /></span>
          </button>
        </div>
      </form>
    </div>
  );
}

export default injectIntl(Login);
